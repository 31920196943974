import {
  changeApiKeyForStoreKey,
  mapObjectParser,
} from '../../services/common/parserCommon';

const pluOtherMapping = {
  cat: 'cat',
  sub_cat: 'subCat',
  master_cat: 'masterCat',
  libelle: 'libelle',
  mapbox_url: 'mapboxUrl',
};

export const zonesAndZoneChildsApiToStore = (datas: any) => {
  const filteredDatas = { ...datas };

  delete filteredDatas.city_name;
  delete filteredDatas.city_zipcode;

  const zones = Object.keys(filteredDatas);
  const zoneChilds: { [x: string]: string[] } = filteredDatas;

  return {
    zones,
    zoneChilds: zoneChilds,
    city: datas.city_name,
    postalCode: datas.city_zipCode,
  };
};
export function pluOthersApiToStoreParser(data: any): PluOthers {
  const parsedData = mapObjectParser(data, pluOtherApiToStoreParser);

  return parsedData;
}
export function pluOtherApiToStoreParser(data: any): PluOther {
  const temp = changeApiKeyForStoreKey<PluOther>(data, pluOtherMapping);
  temp.displayed = false;

  return temp;
}
