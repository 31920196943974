import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import {
  fetchDatasForPdfGenerationThunk,
  fetchPlotForMultiPlotPdfThunk,
  fetchPlotServitudesThunk,
  fetchSweepbrightPdfDataThunk,
} from './externalPdfErrialThunks';

const initialState: externalPdfErrialState = {
  sweepBrightExternalId: '',
  sweepbrightData: { apiStatus: APIStatus.IDLE, result: null },
  externalPdfErrialRightPanelDisplay: true,
  externalPdfErrialFormData: null,
  isMultiPlotSelectorPdf: false,
  multiPlotsPdf: { apiStatus: APIStatus.IDLE, result: null },
  plotCountPdf: 0,
  datasForPdfGeneration: { apiStatus: APIStatus.IDLE, result: null },
  servitudes: { apiStatus: APIStatus.IDLE, result: null },
  infoModalDisplay: {
    display: false,
    type: null,
    plotIds: null,
    errorMessage: null,
  },
  forceGenerate: false,
  clicked: false,
};

const externalPdfErrialSlice = createSlice({
  name: 'externalPdfErrial',
  initialState,
  reducers: {
    reset: () => initialState,
    pdfGenerationReset: (state) => {
      state.clicked = false;
      state.infoModalDisplay = {
        display: false,
        type: null,
        plotIds: null,
        errorMessage: null,
      };
      state.servitudes = { apiStatus: APIStatus.IDLE, result: null };
      state.datasForPdfGeneration = { apiStatus: APIStatus.IDLE, result: null };
      state.forceGenerate = false;
    },
    setClicked: (state, action: PayloadAction<boolean>) => {
      state.clicked = action.payload;
    },
    setForceGenerate: (state, action: PayloadAction<boolean>) => {
      state.forceGenerate = action.payload;
    },
    setSweepBrightExternalId: (state, action: PayloadAction<string>) => {
      state.sweepBrightExternalId = action.payload;
    },
    setInfoModalDisplay: (state, action: PayloadAction<ErrialInfoModalType>) => {
      state.infoModalDisplay = action.payload;
    },
    resetInfoModalDisplay: (state) => {
      state.forceGenerate = false;
      state.infoModalDisplay = {
        display: false,
        type: null,
        plotIds: null,
        errorMessage: null,
      };
    },
    setSweepbrightData: (state, action: PayloadAction<ISweepbrightData>) => {
      state.sweepbrightData.result = action.payload;
    },
    setExternalPdfErrialRightPanelDisplay: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.externalPdfErrialRightPanelDisplay = action.payload;
    },
    setExternalPdfErrialFormData: (
      state,
      action: PayloadAction<IExternalPdfErrialForm>
    ) => {
      state.externalPdfErrialFormData = action.payload;
      state.clicked = true;
    },
    setIsMultiPlotSelectorPdf: (state, action: PayloadAction<boolean>) => {
      if (action.payload === false) {
        state.plotCountPdf = 0;
        state.multiPlotsPdf = { apiStatus: APIStatus.IDLE, result: null };
      }
      state.isMultiPlotSelectorPdf = action.payload;
    },
    removeMultiPlotsPdfFromSelection: (state, action: PayloadAction<number>) => {
      state.multiPlotsPdf.result =
        state.multiPlotsPdf.result
          ?.filter((f) => f.index !== action.payload)
          .map((m, i) => ({ ...m, index: i })) ?? null;
      state.plotCountPdf = state.plotCountPdf - 1;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSweepbrightPdfDataThunk.pending, (state) => {
        state.sweepbrightData.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchSweepbrightPdfDataThunk.fulfilled,
        (state, action: PayloadAction<ISweepbrightData>) => {
          state.sweepbrightData.apiStatus = APIStatus.FULFILLED;
          state.sweepbrightData.result = action.payload ?? [];
        }
      )
      .addCase(
        fetchSweepbrightPdfDataThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.sweepbrightData.apiStatus = APIStatus.REJECTED;
          state.sweepbrightData.result = null;
          state.sweepbrightData.error = action.payload;

          state.infoModalDisplay = {
            display: true,
            type: 'errorSweepbright',
            plotIds: null,
            errorMessage: null,
          };
        }
      );

    builder
      .addCase(fetchPlotForMultiPlotPdfThunk.pending, (state) => {
        state.multiPlotsPdf.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchPlotForMultiPlotPdfThunk.fulfilled,
        (state, action: PayloadAction<Omit<IMultiPlotsPdf, 'index'> | null>) => {
          state.multiPlotsPdf.apiStatus = APIStatus.IDLE;
          state.multiPlotsPdf.error = undefined;
          if (action.payload) {
            const isPlotExists = state.multiPlotsPdf.result?.find(
              (f) => f.fullPlotId === action.payload?.fullPlotId
            );

            if (!isPlotExists) {
              state.multiPlotsPdf.result = state.multiPlotsPdf.result
                ? state.multiPlotsPdf.result.concat({
                    ...action.payload,
                    index: state.multiPlotsPdf.result.length,
                  })
                : [
                    {
                      ...action.payload,
                      index: 0,
                    },
                  ];

              state.plotCountPdf = state.plotCountPdf + 1;
            }
          } else {
            state.infoModalDisplay = {
              display: true,
              type: 'plotNotFound',
              plotIds: null,
              errorMessage: null,
            };
          }
        }
      )
      .addCase(
        fetchPlotForMultiPlotPdfThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.multiPlotsPdf.apiStatus = APIStatus.REJECTED;
          state.multiPlotsPdf.error = action.payload;
        }
      );

    builder
      .addCase(fetchDatasForPdfGenerationThunk.pending, (state) => {
        state.datasForPdfGeneration.apiStatus = APIStatus.PENDING;
        state.datasForPdfGeneration.result = null;
        state.servitudes.result = null;
      })
      .addCase(
        fetchDatasForPdfGenerationThunk.fulfilled,
        (state, action: PayloadAction<DatasForPdfGeneration>) => {
          state.datasForPdfGeneration.result = action.payload;
          state.datasForPdfGeneration.apiStatus = APIStatus.IDLE;
          state.datasForPdfGeneration.error = undefined;
        }
      )
      .addCase(
        fetchDatasForPdfGenerationThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.datasForPdfGeneration.apiStatus = APIStatus.REJECTED;
          state.datasForPdfGeneration.error = action.payload;
        }
      );

    builder
      .addCase(fetchPlotServitudesThunk.pending, (state) => {
        state.servitudes.apiStatus = APIStatus.PENDING;
        state.servitudes.result = null;
        state.servitudes.error = undefined;
      })
      .addCase(
        fetchPlotServitudesThunk.fulfilled,
        (state, action: PayloadAction<OrpiServitudes>) => {
          state.servitudes.apiStatus = APIStatus.IDLE;
          state.servitudes.result = action.payload;
          state.servitudes.error = undefined;
        }
      )
      .addCase(fetchPlotServitudesThunk.rejected, (state) => {
        state.servitudes.apiStatus = APIStatus.REJECTED;
        state.servitudes.result = {
          ponctPresc: null,
          linPresc: null,
          infoSurf: null,
          infoPonc: null,
          servOth: null,
          scot: null,
        };
        state.servitudes.error = undefined;
      });
  },
});

export default externalPdfErrialSlice.reducer;
export const externalPdfErrialActions = externalPdfErrialSlice.actions;
export const getExternalPdfErrialState = (state: RootState) =>
  state.externalPdfErrial;
