export const lastProcedureParser = (
  proc: IStudyProcedure | null
): ILastProcedurePDF | null => {
  if (proc) {
    return {
      responsable: `${proc.responsable.firstName} ${proc.responsable.lastName}`,
      action: proc.type?.label ?? 'indéfini',
      date: proc.date,
      description: proc.comment,
    };
  }

  return null;
};
