import { lastProcedureParser } from '../../lib/parsersTools/procedures';
import { zoneAndZoneChildDisplay } from '../../lib/plotDataFormating';
import { envParse, envPdfParser } from '../../lib/plotPdfFunctions';

export function singlePDFPlotParser(
  data: any,
  plot: IPlot,
  study: IPlotStudy,
  address: string,
  procedure: IStudyProcedure[],
  users: Users,
  statuses: StudyStatuses,
  department: string
): PlotPDFDocument {
  try {
    const lastProcedure = procedure ? procedure[0] : null;
    const user = study?.responsable;
    const status = study?.status;

    return {
      seismic: envParse(envPdfParser(plot.parcelEnv, 1)),
      clay: envParse(envPdfParser(plot.parcelEnv, 5)),
      PEB: envParse(null),
      zone: zoneAndZoneChildDisplay(plot.zone),
      fiscalLaw: plot.fiscalLaw.zone,
      address: ((study as IPlotStudy)?.address ?? address) as string,
      isDomTomOrCorsica: ['20', '97'].includes(department),
      plotId: plot.parcelleId.substring(5),
      plotImg: data.cadastrePicture,
      zoneImg: data.zonePicture,
      areas: {
        real: parseFloat(plot.realCapacity),
        free: parseFloat(plot.areaFree),
        built: parseFloat(plot.areaBuilt),
        calculated: parseFloat(plot.theoricCapacity),
      },
      prospection: study
        ? {
            constructComment: study.comment,
            responsable: `${user?.firstName} ${user?.lastName}`,
            status: status?.label,
            lastProcedure: lastProcedureParser(lastProcedure),
          }
        : null,
    };
  } catch (error) {
    throw new Error('PDF parse error');
  }
}
