import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from '../../../../App/store';
import {
  InternalError,
  paramsNotFound,
} from '../../../../services/axiosFiles/axiosUtils';
import {
  deleteEntity,
  fetchDatas,
  postEntity,
  updateEntity,
} from '../../../../services/axiosFiles/genericCrud';
import { modalsActions } from '../../../modals/modalsSlice';

import { fetchPluZoneFromPlotListSlice } from '../../../../shared/services/fetchPluZoneFromPlotList';
import { displayManagerActions } from '../../../displayManager/displayManagerSlice';
import { getParentAndChildIdIriTab } from '../../../displayManager/utils';
import { checkAndUpdatePlotStudyDatas } from '../../../study/services/checkAndUpdatePlotStudyDatas';
import { folderParser, foldersParser } from '../../folderParser';
import { foldersActions } from '../../foldersSlice';
import { handleExportExcel } from '../../utils/handleExportExcel';
import { fetchFolderPlotStudies } from '../fetchFolderPlotStudies';
import { fetchFolderSynthese } from '../fetchFolderSynthese';
import { loadersActions } from '../../../loaders/loaderSlice';

// *********************************************************
// ******************** FETCH FOLDERS **********************
// *********************************************************
export const fetchFoldersThunk = createAsyncThunk(
  'folders/fetchFoldersThunk',
  async (params: { companyIdIri: string | null }, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const users = state.users.users;

      if (params.companyIdIri) {
        const endpoint = params.companyIdIri + '/folders';
        const promise = fetchDatas(endpoint);

        return promise
          .then(
            (response) => {
              if (users) {
                const parsedResponse = foldersParser(
                  response['hydra:member'],
                  users
                );
                return parsedResponse;
              } else {
                return rejectWithValue({
                  status: 400,
                  message: 'need users or statuses for parse data',
                });
              }
            },
            (err) => {
              return rejectWithValue(err);
            }
          )
          .catch((error) => {
            return rejectWithValue(InternalError(error));
          });
      } else {
        return rejectWithValue({ status: 0, message: 'companyIdIri param missing' });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);

// *********************************************************
// ******* FETCH FOLDER PLOT STUDIES BY FOLDER ID *********
// *********************************************************
export const fetchFolderPlotStudiesThunk = createAsyncThunk(
  'folders/fetchFolderPlotStudiesThunk',
  async (
    params: {
      folder: IFolder | null;
      forDisplay?: boolean;
      body: QueryParams;
      isDashboard?: boolean;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const { users } = state.users;
      const { plotStudyStatuses } = state.app;

      if (params.folder && params.body) {
        //if folder is displayed, plotStudies are allready loaded
        // if (params.folder?.displayed) {
        //   return null;
        // }

        // if plotStudies allready loaded and display = true => no load
        if (!isEmpty(params.folder.plotStudies) && params.forDisplay) {
          dispatch(foldersActions.singleFolderOnMapShow(params.folder));
          return null;
        }

        const plotStudies = await fetchFolderPlotStudies(
          params.folder,
          params.body,
          users ?? [],
          plotStudyStatuses.result ?? []
        );

        return {
          folderIdIri: params.folder?.idIri as string,
          isSub: Boolean(params.folder?.parent),
          plotStudies: plotStudies,
          forDisplay: params.forDisplay ?? false,
        };
      } else {
        return rejectWithValue(paramsNotFound());
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);

// *********************************************************
// ******************** CREATE FOLDER **********************
// *********************************************************
export const folderCreateThunk = createAsyncThunk(
  'folders/folderCreateThunk',
  async (
    params: {
      name: string;
      markerColor: string;
      parentFolder: string | null;
      company: string;
      callback: () => void;
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const users = state.users.users;
      const userIdIri = state.auth.userIdIri;
      const { name, markerColor, company } = params;

      if (name && markerColor && company && userIdIri) {
        const result = await postEntity({
          endpoint: '/folders',
          body: { ...params, responsable: userIdIri },
        });

        if (users) {
          const parsedFolder = folderParser(result, users);
          params.callback();

          return parsedFolder;
        } else {
          return rejectWithValue({
            status: 400,
            message: 'need users or statuses for parse data',
          });
        }
      } else {
        return rejectWithValue({
          status: 0,
          message: 'userIdIri or folder param missing',
        });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);

// *********************************************************
// ******************** UPDATE FOLDER **********************
// *********************************************************
export const folderUpdateThunk = createAsyncThunk(
  'folders/folderUpdateThunk',
  async (
    params: {
      folderIdIri: string;
      folder: Pick<IFolder, 'name' | 'markerColor' | 'indexId'>;
      callback: () => void;
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const users = state.users.users;
      const { folderIdIri, folder } = params;

      if (folderIdIri && folder) {
        const promise = updateEntity({
          idIri: params.folderIdIri,
          body: { ...params.folder },
        });

        return promise.then(
          (response) => {
            if (users) {
              const parsedFolder = folderParser(response, users);
              params.callback();
              return parsedFolder;
            } else {
              return rejectWithValue({
                status: 400,
                message: 'need users or statuses for parse data',
              });
            }
          },
          (err) => {
            return rejectWithValue(err);
          }
        );
      } else {
        return rejectWithValue({
          status: 0,
          message: 'userIdIri or folder param missing',
        });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);

// *********************************************************
// ******************** DELETE FOLDER **********************
// *********************************************************
export const folderDeleteThunk = createAsyncThunk(
  'folders/folderDeleteThunk',
  async (
    params: {
      folder: IFolder | null;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      if (params.folder?.idIri) {
        dispatch(loadersActions.loaderShow());
        await deleteEntity(params.folder.idIri);

        //remove plot studies folder from entities display
        dispatch(
          displayManagerActions.entitiesRemoveByParent(
            getParentAndChildIdIriTab(params.folder)
          )
        );
        dispatch(foldersActions.folderForActionReset());
        dispatch(modalsActions.folderDelete(false));
        dispatch(loadersActions.loaderHide());
        return params.folder;
      } else {
        dispatch(loadersActions.loaderHide());
        return rejectWithValue({
          status: 0,
          message: 'folderIdIri param missing',
        });
      }
    } catch (error) {
      dispatch(loadersActions.loaderHide());
      return rejectWithValue(error);
    }
  }
);

// *********************************************************
// **************** FETCH FOLDER SYNTHESE ******************
// *********************************************************
export const fetchSyntheseFolderThunk = createAsyncThunk(
  'folders/fetchSyntheseFolderThunk',
  async (params: { plotStudies: PlotStudies }, { rejectWithValue }) => {
    try {
      if (params.plotStudies) {
        const synthese = fetchFolderSynthese(params.plotStudies);

        return synthese;
      } else {
        return rejectWithValue({
          status: 0,
          message: 'folder param missing',
        });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);

// *********************************************************
// ********* EXPORT PLOTSTUDIES FOLDER TO EXCEL ***********
// *********************************************************
export const fetchFolderPlotStudiesForExcel = createAsyncThunk(
  'folders/fetchFolderPlotStudiesForExcel',
  async (params: { folder: IFolder }, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const { users } = state.users;
      const { plotStudyStatuses } = state.app;

      if (params.folder) {
        const updatedPlotStudies: PlotStudies = [];
        const plotStudies = await fetchFolderPlotStudies(
          params.folder,
          {},
          users ?? [],
          plotStudyStatuses.result ?? []
        );

        // fetch actual zones from python
        const pluZones = await fetchPluZoneFromPlotListSlice(plotStudies);

        for (const ps of plotStudies) {
          const pz = pluZones[ps.fullPlotId]?.plu_zone;
          if (ps.zone !== pz.zone || ps.zoneChild !== pz.zone_child) {
            ps.zone = pz.zone;
            ps.zoneChild = pz.zone_child;
          }

          const newPs = await checkAndUpdatePlotStudyDatas({
            ps,
            zones: { zone: pz.zone, zoneChild: pz.zone_child },
          });
          updatedPlotStudies.push(newPs);
        }

        handleExportExcel(params.folder.name, updatedPlotStudies);
      } else {
        return rejectWithValue({ status: 0, message: 'folder param missing' });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);
