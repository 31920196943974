export const euroFormat = (value: number): string => {
  return !value
    ? '-'
    : new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      }).format(value);
};

export const spacedNumbers = (number: number) => {
  if (isNaN(number)) {
    return number;
  }
  return new Intl.NumberFormat('fr-FR', { maximumSignificantDigits: 3 }).format(
    number
  );
};

export function spaceNumberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function distinctElementsFromArray<T>(item: T, i: number, tab: T[]): boolean {
  return tab.indexOf(item) === i;
}

export const formatedNumber = (
  value: nullable<number>,
  unit: string,
  isFloat = false
) => {
  if (!value) {
    return ' -';
  }

  return !isNaN(value || NaN)
    ? isFloat
      ? ` ${spacedNumbers(parseFloat(value?.toFixed(2) || ''))} ${unit}`
      : ` ${spacedNumbers(value as number)} ${unit}`
    : ' -';
};

export const firstNameAndInitialParser = (
  fName: string | null,
  lName: string | null
): string => {
  if (fName && lName) {
    return `${fName} ${lName.charAt(0).toUpperCase()}`;
  }

  return 'Indéfini';
};

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return true;
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return true;
  }

  return false;
}

export function decodeText(text: string) {
  const decodedText = document.createElement('textarea');
  decodedText.innerHTML = text;
  return decodedText.value;
}

export const imageDataParse = (dataImg: string) => {
  if (!dataImg) return '';
  return dataImg ? dataImg.slice(2, dataImg.length - 1) : '';
};
