import { getZoneAndZoneChildString } from '../../lib/formats/zoneAndZoneChildFormat';
import getEntityFromIdIri from '../../lib/parsersTools/getEntityFromIdIri';
import getIdFromIdIri from '../../lib/parsersTools/getIdFromIdIri';
import { documentsBuilder } from '../../lib/plotDataFormating';
import { changeApiKeyForStoreKey } from '../../services/common/parserCommon';
import {
  ownersListParser,
  parcelEnvBuilder,
  taxesParser,
  townHallParser,
  transactionsParser,
} from '../../services/parserDatas/parcelleParser';
import genericSort from '../../utils/genericSort';
import { firstNameAndInitialParser } from '../../utils/jsFunctions';
import { envParse, envPdfParser } from './utils';

export const folderMapping = {
  '@id': 'idIri',
  responsable: 'responsable',
  status: 'status',
  comment: 'comment',
  feasibilityMatrix: 'feasibilityMatrix',
  name: 'name',
  markerColor: 'markerColor',
  parentFolder: 'parent',
};

export const folderParser = (data: any, users: Users): IFolder => {
  try {
    const parsedData: IFolder = changeApiKeyForStoreKey(data, folderMapping);

    parsedData.id = getIdFromIdIri(data['@id']);
    parsedData.sortValues = { name: data.name, id: parsedData.id };

    // serialise responsable from user
    parsedData.responsable = getEntityFromIdIri(data.responsable, users) as User;

    // get only folder idIri
    parsedData.parent = data.parentFolder
      ? typeof data.parentFolder === 'string'
        ? data.parentFolder
        : data.parentFolder['@id']
      : null;

    parsedData.plotStudies = [];
    parsedData.plotStudiesCount = data.plotStudies?.length ?? 0;

    parsedData.subs = [];
    parsedData.subCount = data.subFolders?.length ?? 0;
    return parsedData;
  } catch (error) {
    throw new Error("the folder can't be parsed");
  }
};
export const foldersParser = (data: any, users: Users): Folders => {
  try {
    const tempFolders: Folders = data.map((f: any) => folderParser(f, users));

    const sortedTempFolders = genericSort(tempFolders, 'asc', 'id') as Folders;

    const masterFolders = sortedTempFolders
      .filter((f) => !f.parent)
      .map((f, i) => ({
        ...f,
        indexId: i + 1,
        subs: [],
        plotStudies: f.plotStudies.map((ps, psi) => ({
          ...ps,
          indexId: psi + 1,
        })),
      }));

    masterFolders.forEach((folder) => {
      const subs = tempFolders
        .filter((f) => f.parent === folder.idIri)
        .map((f, i) => ({
          ...f,
          indexId: i + 1,
          plotStudies: f.plotStudies.map((ps, psi) => ({
            ...ps,
            indexId: psi + 1,
          })),
        }));

      folder.subs = genericSort(subs, 'asc', 'id');
    });

    return masterFolders;
  } catch (error) {
    return [];
  }
};
const pdfDataContentBuilder = (
  key: string,
  data: any,
  plotStudies: PlotStudies,
  department: string
): PlotPDFDocument => {
  const plot = data[key];

  const plotStudy = plotStudies.find((p) => p.fullPlotId === key);
  const parsedEnvs = parcelEnvBuilder(plot.environmental_risks);
  const result: PlotPDFDocument = {
    studyIdIri: plotStudy?.idIri,
    seismic: envParse(envPdfParser(parsedEnvs, 1)),
    clay: envParse(envPdfParser(parsedEnvs, 5)),
    PEB: envParse(null),
    zone: getZoneAndZoneChildString(plot.zone),
    fiscalLaw: plot.fiscal_law.zone,
    address: plotStudy?.address || 'indéfini',
    isDomTomOrCorsica: ['20', '97'].includes(department),
    plotId: key.substring(5),
    plotImg: '',
    zoneImg: '',
    areas: {
      real: parseFloat(plot.parcel_areas.real_capacity),
      free: parseFloat(plot.parcel_areas.area_free),
      built: parseFloat(plot.parcel_areas.area_built),
      calculated: parseInt(plot.parcel_areas.theoric_capacity, 10),
    },
    prospection: {
      responsable: `${(plotStudy?.responsable as User)?.firstName} ${
        (plotStudy?.responsable as User)?.lastName
      }`,
      status: plotStudy?.status?.label,
      constructComment: '',
      lastProcedure: null,
    },
  };

  return result;
};
export const folderSyntheseParser = (
  data: any,
  plotStudies: PlotStudies
): ISubFolderDataResults | null => {
  try {
    const areas = data.total_parcels_areas;

    const keys = Object.keys(data);
    const model = data[keys[0]];
    keys.pop();
    const table: ISyntheseTableRow[] = [];
    const folderPlotZones: { plotId: string; zone: IZone }[] = [];
    const ownersPlots: IOwnersPlots[] = [];
    const envRiskDatas: IEnvRiskDatas[] = [];
    const pdfDataRequest: IPdfDataRequest = {};
    const pdfTabContent: PlotPDFDocument[] = [];
    const plotCount: number = keys.length;

    const notFoundPlot: string[] = [];
    // for each plot
    keys.forEach((k) => {
      const el = data[k];

      const plotStudy = plotStudies.find((ps) => ps.fullPlotId === k) as IPlotStudy;
      folderPlotZones.push({
        plotId: k.substring(5),
        zone: { zone: el.zone.zone, zoneChild: el.zone.zone_child },
      });

      pdfDataRequest[k] = {
        parcel_geometry: el.parcel_areas.parcel_geometry,
        mapbox_cadastral_url: el.mapbox_cadastral_url,
        mapbox_zone_url: el.zone.mapbox_url,
      };

      pdfTabContent.push(
        pdfDataContentBuilder(k, data, plotStudies, plotStudy.department)
      );

      ownersPlots.push({
        plotId: k.substring(5),
        address: plotStudy?.address || '',
        city: plotStudy?.city || '',
        postalCode: plotStudy?.postalCode || '',
        ownerList: ownersListParser(el.owners_list.datas),
        impotsMails: model.contact_impots,
      });

      envRiskDatas.push({
        plotId: k.substring(5),
        section: el.section,
        prefix: el.prefixe,
        number: el.numero,
        city: el.city_name,
        postalCode: el.zipcode,
        inseeCode: el.insee_code,
        latLng: [el.latitude, el.longitude],
        envs: parcelEnvBuilder(el.environmental_risks),
      });

      const kAreas = el.parcel_areas;
      const userData = plotStudy?.responsable;

      if (!kAreas.theoric_capacity) {
        notFoundPlot.push(k.substring(5));
      }

      const temp: ISyntheseTableRow = {
        city: el.city_name,
        address: el.mapAddress,
        plotId: k.substring(5),
        usedGroundArea: kAreas.area_built ?? 0,
        plotArea: kAreas.theoric_capacity ?? 0,
        zone: el.zone.zone_child
          ? `${el.zone.zone}-${el.zone.zone_child}`
          : el.zone.zone,
        responsable: userData
          ? firstNameAndInitialParser(userData?.firstName, userData?.lastName)
          : '-',
        state: plotStudy?.status,
      };

      table.push(temp);
    });

    const syntheseTab: ISyntheseTab = {
      notFoundPlot,
      totalFreeGroundArea: areas?.total_areas_free ?? 0,
      totalPlotArea: areas?.total_theoric_capacities ?? 0,
      totalUsedGroundArea: areas?.total_areas_built ?? 0,
      plotsData: table,
    };

    const documents = model.documents ? documentsBuilder(model.documents) : [];

    const urbanismeTab = {
      plot: {
        documents,
        zone: <IZone>model.zone,
      },
      folderPlotZones,
    };

    const folderInfosTab = {
      plot: {
        transactions: transactionsParser(model.transactions),
        ownerList: {
          list: model.owners_list?.datas
            ? ownersListParser(model.owners_list.datas)
            : [],
        },

        envRisks: model.environmental_risks
          ? parcelEnvBuilder(model.environmental_risks)
          : [],
        fiscalZone: {
          eligibility: model.fiscal_law?.eligibility ?? ' - ',
          zone: model.fiscal_law?.zone ?? ' - ',
        },
        fiscalLaw: model.fiscal_law,
        taxes: model.taxes ? taxesParser(model.taxes) : [],
        townHall: model.town_hall_contact
          ? townHallParser(model.town_hall_contact)
          : null,
      },
      ownersPlots,
      envRiskDatas,
    };
    const cadastralLayer = model.mapbox_cadastral_url;

    return {
      syntheseTab,
      urbanismeTab,
      folderInfosTab,
      cadastralLayer,
      pdfDataRequest,
      pdfTabContent,
      plotCount,
    };
  } catch (error) {
    return null;
  }
};
