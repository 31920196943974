import { createAsyncThunk } from '@reduxjs/toolkit';
import { InternalError } from '../../services/axiosFiles/axiosUtils';
import { fetchDatas } from '../../services/axiosFiles/genericCrud';
import { layerEnvParser } from './layerEnvParser';

export const fetchMapLayerEnvThunk = createAsyncThunk(
  'layerEnv/fetchMapLayerEnvThunk',
  async (
    params: { city: string | null; postalCode: string | null },
    { rejectWithValue }
  ) => {
    try {
      const { city, postalCode } = params;
      if (city && postalCode) {
        const promise = fetchDatas('/get_plu_environment_list_from_city', {
          city_zipcode: postalCode,
          city_name: city,
        });

        return promise
          .then(
            (response) => {
              const parsedEnv = layerEnvParser(response);

              return { city, postalCode, envLayers: parsedEnv };
            },
            (err) => {
              return rejectWithValue(err);
            }
          )
          .catch((error) => {
            return rejectWithValue(InternalError(error));
          });
      } else {
        return rejectWithValue({
          status: 0,
          message: 'city or postalCode param missing',
        });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);
