import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import {
  fetchFolderPlotStudiesThunk,
  fetchFoldersThunk,
  fetchSyntheseFolderThunk,
  folderCreateThunk,
  folderDeleteThunk,
  folderUpdateThunk,
} from './services/thunks/foldersThunk';

const initialState: FolderState = {
  folders: { apiStatus: APIStatus.IDLE, result: null },
  folderForAction: null,
  folderRightPanelDatas: { apiStatus: APIStatus.IDLE, result: null },
  subFolderParent: null,
  deployedFolder: null,
  deployedSubFolder: null,
  totalFolders: 0,
  totalFolderPlotStudies: 0,
  isLoading: false,
};

const foldersSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    folderRightPanelDatasReset: (state) => {
      state.folderRightPanelDatas = { apiStatus: APIStatus.IDLE, result: null };
    },
    deployedFolderSet: (state, action: PayloadAction<IFolder>) => {
      state.deployedFolder = action.payload;
    },
    deployedFolderReset: (state) => {
      state.deployedFolder = null;
    },
    deployedSubFolderSet: (state, action: PayloadAction<IFolder>) => {
      state.deployedSubFolder = action.payload;
    },
    deployedSubFolderReset: (state) => {
      state.deployedSubFolder = null;
    },
    allDeployedFoldersReset: (state) => {
      state.deployedFolder = null;
      state.deployedSubFolder = null;
    },
    folderForActionSet: (state, action: PayloadAction<IFolder>) => {
      state.folderForAction = action.payload;
    },
    folderForActionReset: (state) => {
      state.folderForAction = null;
    },
    subFolderParentSet: (state, action: PayloadAction<string>) => {
      state.subFolderParent = action.payload;
    },
    subFolderParentReset: (state) => {
      state.subFolderParent = null;
    },
    displayAllAffectFolderPlotStudies: (state, action: PayloadAction<IFolder>) => {
      state.folders.result =
        state.folders.result?.map((f) =>
          f.idIri === action.payload.idIri ? action.payload : f
        ) ?? null;
    },
    allFoldersOnMapHide: (state) => {
      state.folders.result =
        state.folders.result?.map((f) => ({
          ...f,
          displayed: false,
          subs: f.subs?.map((sf) => ({ ...sf, displayed: false })) ?? null,
        })) ?? null;
    },
    singleFolderOnMapShow: (state, action: PayloadAction<IFolder>) => {
      const ap = action.payload;

      state.folders.result =
        state.folders.result?.map((f) => {
          if (f.idIri === ap.parent) {
            // if f = parent folder, we display subfolder
            return {
              ...f,
              subs:
                f.subs?.map((sf) =>
                  sf.idIri === ap.idIri ? { ...sf, displayed: true } : sf
                ) ?? null,
            };
          } else if (f.idIri === ap.idIri) {
            //if f is the folder to update, we display folder
            return { ...f, displayed: true };
          } else {
            // if no matching, return f
            return f;
          }
        }) ?? null;
    },
    singleFolderOnMapHide: (state, action: PayloadAction<IFolder>) => {
      const ap = action.payload;
      state.folders.result =
        state.folders.result?.map((f) => {
          if (f.idIri === ap.parent) {
            // if f = parent folder, we hide subfolder
            return {
              ...f,
              subs:
                f.subs?.map((sf) =>
                  sf.idIri === ap.idIri ? { ...sf, displayed: false } : sf
                ) ?? null,
            };
          } else if (f.idIri === ap.idIri) {
            //if f is the folder to update, we hide folder
            return {
              ...f,
              displayed: false,
              subs: f.subs?.map((sf) => ({ ...sf, displayed: false })) ?? null,
            };
          } else {
            // if no matching, return f
            return f;
          }
        }) ?? null;
    },
    updatePlotStudyCount: (
      state,
      action: PayloadAction<{
        folderIdIri: string;
        count: number;
      }>
    ) => {
      if (state.folders.result) {
        state.folders.result = state.folders.result?.map((f) => {
          return f.idIri === action.payload.folderIdIri
            ? {
                ...f,
                plotStudiesCount: f.plotStudiesCount + action.payload.count,
                displayed: false,
              }
            : f.subs
            ? {
                ...f,
                subs: f.subs.map((sf) => {
                  return sf.idIri === action.payload.folderIdIri
                    ? {
                        ...sf,
                        plotStudiesCount: sf.plotStudiesCount + action.payload.count,
                        displayed: false,
                      }
                    : sf;
                }),
              }
            : f;
        });
      }
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // *********************************************************
      // ******************** FETCH FOLDERS **********************
      // *********************************************************
      .addCase(fetchFoldersThunk.pending, (state) => {
        state.folders.apiStatus = APIStatus.PENDING;
        state.folders.result = null;
        state.folders.error = undefined;
      })
      .addCase(
        fetchFoldersThunk.fulfilled,
        (state, action: PayloadAction<Folders>) => {
          state.folders.apiStatus = APIStatus.IDLE;
          state.folders.error = undefined;
          state.folders.result = action.payload;
          state.deployedFolder = null;
          state.deployedSubFolder = null;
        }
      )
      .addCase(fetchFoldersThunk.rejected, (state, action: PayloadAction<any>) => {
        state.folders.apiStatus = APIStatus.REJECTED;
        state.folders.error = action.payload;
      });
    // *********************************************************
    // ******* FETCH FOLDER PLOT STUDIES BY FOLDER ID *********
    // *********************************************************
    builder
      .addCase(fetchFolderPlotStudiesThunk.pending, (state) => {
        state.folders.apiStatus = APIStatus.PENDING;
        state.folders.error = undefined;
      })
      .addCase(
        fetchFolderPlotStudiesThunk.fulfilled,
        (
          state,
          action: PayloadAction<{
            folderIdIri: string;
            isSub: boolean;
            plotStudies: PlotStudies;
            forDisplay: boolean;
          } | null>
        ) => {
          state.folders.apiStatus = APIStatus.IDLE;

          if (action.payload !== null) {
            const { folderIdIri, isSub, plotStudies, forDisplay } = action.payload;
            state.folders.error = undefined;
            state.folders.result = (state.folders.result as Folders).map((f) => {
              return isSub
                ? // if subfolder
                  {
                    ...f,
                    subs:
                      f.subs?.map((sf) => {
                        return sf.idIri === folderIdIri
                          ? {
                              ...sf,
                              plotStudies: plotStudies,
                              displayed: forDisplay,
                            }
                          : sf;
                      }) ?? null,
                  }
                : f.idIri === folderIdIri
                ? //if folder
                  { ...f, plotStudies: plotStudies, displayed: forDisplay }
                : f;
            });
          }
        }
      )
      .addCase(
        fetchFolderPlotStudiesThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.folders.apiStatus = APIStatus.REJECTED;
          state.folders.error = action.payload;
        }
      );
    // *********************************************************
    // ******************** CREATE FOLDER **********************
    // *********************************************************
    builder
      .addCase(folderCreateThunk.pending, (state) => {
        state.folders.apiStatus = APIStatus.PENDING;
        state.folders.error = undefined;
      })
      .addCase(
        folderCreateThunk.fulfilled,
        (state, action: PayloadAction<IFolder>) => {
          const ap = action.payload;

          state.folders.apiStatus = APIStatus.IDLE;
          state.folders.error = undefined;
          if (ap.parent) {
            state.folders.result =
              state.folders.result?.map((f) =>
                f.idIri === ap.parent
                  ? {
                      ...f,
                      subCount: f.subCount + 1,
                      subs:
                        f.subs?.concat({
                          ...ap,
                          indexId: f.subCount + 1,
                        }) ?? null,
                    }
                  : f
              ) ?? null;
          } else {
            if (state.folders.result) {
              const indexedAp = { ...ap, indexId: state.folders.result.length + 1 };
              state.folders.result = state.folders.result?.concat(indexedAp);
            } else {
              state.folders.result = [{ ...ap, indexId: 1 }];
            }
          }
        }
      )
      .addCase(folderCreateThunk.rejected, (state, action: PayloadAction<any>) => {
        state.folders.apiStatus = APIStatus.REJECTED;
        state.folders.error = action.payload;
      });
    // *********************************************************
    // ******************** UPDATE FOLDER **********************
    // *********************************************************
    builder
      .addCase(folderUpdateThunk.pending, (state) => {
        state.folders.apiStatus = APIStatus.PENDING;
        state.folders.error = undefined;
      })
      .addCase(
        folderUpdateThunk.fulfilled,
        (state, action: PayloadAction<IFolder>) => {
          const ap = action.payload;

          state.folders.apiStatus = APIStatus.IDLE;
          state.folders.error = undefined;
          if (ap.parent) {
            state.folders.result =
              state.folders.result?.map((f) =>
                f.idIri === ap.parent
                  ? {
                      ...f,
                      subs:
                        f.subs?.map((sf) =>
                          sf.idIri === ap.idIri ? { ...ap, indexId: sf.indexId } : sf
                        ) ?? null,
                    }
                  : f
              ) ?? null;
          } else {
            state.folders.result =
              state.folders.result?.map((f) =>
                f.idIri === ap.idIri
                  ? { ...f, name: ap.name, markerColor: ap.markerColor }
                  : f
              ) ?? null;
          }
          console.log(
            state.deployedFolder?.idIri,
            state.deployedSubFolder?.idIri,
            ap.idIri
          );
          if (state.deployedFolder?.idIri === ap.idIri) {
            state.deployedFolder = null;
            state.deployedSubFolder = null;
          }

          if (state.deployedSubFolder?.idIri === ap.idIri) {
            state.deployedSubFolder = null;
          }
        }
      )
      .addCase(folderUpdateThunk.rejected, (state, action: PayloadAction<any>) => {
        state.folders.apiStatus = APIStatus.REJECTED;
        state.folders.error = action.payload;
      });
    // *********************************************************
    // ******************** DELETE FOLDER **********************
    // *********************************************************
    builder
      .addCase(folderDeleteThunk.pending, (state) => {
        state.folders.apiStatus = APIStatus.PENDING;
        state.folders.error = undefined;
      })
      .addCase(
        folderDeleteThunk.fulfilled,
        (state, action: PayloadAction<IFolder>) => {
          const ap = action.payload;
          state.folders.apiStatus = APIStatus.IDLE;
          state.folders.error = undefined;

          if (ap.parent) {
            state.folders.result =
              state.folders.result?.map((f) =>
                f.idIri === ap.parent
                  ? {
                      ...f,
                      subCount: f.subCount - 1,
                      subs:
                        f.subs
                          ?.filter((sf) => sf.idIri !== ap.idIri)
                          .map((sf, i) => ({ ...sf, indexId: i + 1 })) ?? null,
                    }
                  : f
              ) ?? null;
          } else {
            state.folders.result =
              state.folders.result
                ?.filter((f) => f.idIri !== ap.idIri)
                .map((f, i) => ({ ...f, indexId: i + 1 })) ?? null;
          }
        }
      )
      .addCase(folderDeleteThunk.rejected, (state, action: PayloadAction<any>) => {
        state.folders.apiStatus = APIStatus.REJECTED;
        state.folders.error = action.payload;
      });
    builder
      .addCase(fetchSyntheseFolderThunk.pending, (state) => {
        state.folderRightPanelDatas.apiStatus = APIStatus.PENDING;
        state.folderRightPanelDatas.result = null;
        state.folderRightPanelDatas.error = undefined;
      })
      .addCase(
        fetchSyntheseFolderThunk.fulfilled,
        (state, action: PayloadAction<ISubFolderDataResults | null>) => {
          state.folderRightPanelDatas.apiStatus = APIStatus.IDLE;
          state.folderRightPanelDatas.error = undefined;
          state.folderRightPanelDatas.result = action.payload;
        }
      )
      .addCase(
        fetchSyntheseFolderThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.folderRightPanelDatas.apiStatus = APIStatus.REJECTED;
          state.folderRightPanelDatas.error = action.payload;
        }
      );
  },
});

export default foldersSlice.reducer;
export const foldersActions = foldersSlice.actions;
export const getFoldersState = (state: RootState) => state.folders;
