import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { InternalError } from '../../../../services/axiosFiles/axiosUtils';
import { displayManagerActions } from '../../displayManagerSlice';
import fetchFolderAndSubsPlotStudiesForDisplay from '../fetchFolderAndSubsPlotStudiesForDisplay';

export const fetchAllFolderPlotStudiesForDisplayThunk = createAsyncThunk(
  'displayManager/fetchAllFolderPlotStudiesForDisplayThunk',
  async (
    params: { folders: Folders | null },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const { users } = state.users;
      const { plotStudyStatuses } = state.app;

      if (params.folders) {
        await Promise.all(
          params.folders.map((m) =>
            fetchFolderAndSubsPlotStudiesForDisplay(
              m,
              users ?? [],
              plotStudyStatuses.result ?? []
            ).then(
              (res) => {
                dispatch(displayManagerActions.folderEntitiesAdd(res));
              },
              (err) => {
                console.log('folders can t be loaded');
              }
            )
          )
        );
      } else {
        return rejectWithValue({ status: 0, message: 'folder param missing' });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);
