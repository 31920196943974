import { TextField } from '@material-ui/core';
import { FocusEvent } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldError,
  FieldValues,
} from 'react-hook-form';
import TooltipCustom from '../../../features/advancedPlotSearch/advancedPlotSearchContainer/TooltipCustom';
import styles from './textFieldFormCustom.module.scss';

interface IFieldInputFormCustom {
  name: string;
  control: any;
  onChange?: ChangeEventCustom;
  label?: nullable<string>;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  errorMsg?: string;
  type?: string;
  className?: string;
  readOnly?: boolean;
  required?: boolean;
  InputProps?: any;
  onFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;

  errorTooltip?: FieldError;
}
export default function TextFieldFormCustom({
  name,
  control,
  label,
  disabled,
  error,
  errorMsg,
  type = 'text',
  className,
  readOnly = false,
  onChange,
  onFocus,
  required,
  placeholder,
  InputProps,

  errorTooltip,
}: IFieldInputFormCustom) {
  const handleChange = (
    e: ChangeEventCustom,
    field: ControllerRenderProps<FieldValues, string>
  ) => {
    switch (type) {
      case 'number':
        field.onChange(parseFloat(e.target.value));
        break;
      case 'text':
      default:
        field.onChange(e.target.value);
        break;
    }

    if (onChange) {
      onChange(e);
    }
  };

  const handleFocus = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    if (onFocus) {
      onFocus(e);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          {errorTooltip ? (
            <TooltipCustom error={errorTooltip}>
              <TextField
                {...field}
                type={type}
                variant="outlined"
                disabled={disabled}
                label={label}
                error={error}
                placeholder={placeholder}
                className={`${className} ${error ? styles.error : ''}`}
                onFocus={handleFocus}
                InputProps={{ readOnly, ...InputProps }}
                onChange={(e) => handleChange(e, field)}
                required={required}
              />
            </TooltipCustom>
          ) : (
            <TextField
              {...field}
              type={type}
              variant="outlined"
              disabled={disabled}
              label={label}
              error={error}
              helperText={errorMsg}
              placeholder={placeholder}
              className={className}
              onFocus={handleFocus}
              InputProps={{ readOnly, ...InputProps }}
              onChange={(e) => handleChange(e, field)}
              required={required}
            />
          )}
        </>
      )}
    />
  );
}
