import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';
import { useAppDispatch } from '../App/store';
import Logo from '../assets/images/logo.png';
import FieldInputPwdFormCustom from '../components/Common/FormComponents/FieldInputPwdFormCustom';
import TextFieldFormCustom from '../components/Common/FormComponents/TextFieldFormCustom';
import { validationMessages } from '../constants/validations';
import { resetPasswordThunk } from '../features/auth/authThunks';
import './styles/passwordReset.scss';

const validationSchemaForm = Yup.object({
  email: Yup.string()
    .email(validationMessages.email.error)
    .required(validationMessages.email.requis),
  password: Yup.string().required('Mot de passe requis'),
  passwordConfirm: Yup.string()
    .required('La confirmation du mot de passe est requise')
    .oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas'),
  token: Yup.string().required(),
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

type FormType = {
  email: string;
  password: string;
  passwordConfirm: string;
  token: string;
};

export default function PasswordResetPage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;
  const query = useQuery();

  useEffect(() => {
    if (query && !query.get('token_password')) {
      window.location.href = 'https://app.urbanease.io/';
    }
  }, [query]);

  const useFormValues = useForm<FormType>({
    defaultValues: {
      email: query.get('email') as string,
      password: '',
      passwordConfirm: '',
      token: query.get('token_password') as string,
    },
    resolver: yupResolver(validationSchemaForm),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormValues;

  const submitForm = (data: FormType): void => {
    switch (path) {
      case '/reset_password':
        dispatch(resetPasswordThunk({ ...data, history }));
        break;
      default:
        break;
    }
  };

  const handleClick = () => {
    window.location.href = 'https://app.urbanease.io/';
  };

  return (
    <div className="main-wrapper-password-reset">
      <div className="header-wrapper">
        <div className="header-col-left">
          <img src={Logo} alt="logo" />
        </div>
        <button onClick={handleClick}>Retour sur Urbanease</button>
      </div>
      <div className="body-wrapper">
        <div className="password-header">
          <h2>Changement de mot de passe</h2>
          <span>Je souhaite changer mon mot de passe</span>
        </div>

        <FormProvider {...useFormValues}>
          <form onSubmit={handleSubmit(submitForm)} autoComplete="off">
            <TextFieldFormCustom
              type="email"
              control={control}
              name="email"
              placeholder="Email"
              error={Boolean(errors.email)}
              errorMsg={errors.email?.message as string}
            />
            <FieldInputPwdFormCustom name="password" placeholder="Mot de passe" />
            <FieldInputPwdFormCustom
              name="passwordConfirm"
              placeholder="Confirmation du mot de passe"
            />
            <button type="submit" className="submit-btn">
              ENVOYER
            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
