import { RootState } from '../../App/store';
// import { CHANGE_COMPANY } from '../auth/actions';
import { REQUEST, SET, SUCCESS } from '../common/actions';
import {
  DISPLAY_BUILDING_CENTROID,
  FETCH_PLOT,
  FETCH_PLOTS_BY_USER_ID,
  FETCH_PLOT_BY_PLOT_ID,
  PLOT_RESET,
  POLYLINE_MEASURER_ENABLE,
} from './actions';

const initialState: IPlotState = {
  parcelle: null,
  usersPlots: [],
  displayBuildingCentroid: false,
  polylineMeasurerEnable: false,
};

const plotReducer = (
  state: IPlotState = initialState,
  { type, payload }: IAction<string, IPlotState | IPlotStudy | boolean>
): IPlotState => {
  switch (type) {
    case FETCH_PLOT[SUCCESS]:
    case FETCH_PLOT_BY_PLOT_ID[SUCCESS]:
      return {
        ...state,
        parcelle: (<IPlotState>payload).parcelle,
      };

    case FETCH_PLOTS_BY_USER_ID[SUCCESS]:
      return {
        ...state,
        usersPlots: state.usersPlots.concat(payload),
      };
    case POLYLINE_MEASURER_ENABLE[REQUEST]:
      return {
        ...state,
        polylineMeasurerEnable: (<IPlotState>payload).polylineMeasurerEnable,
      };
    case PLOT_RESET:
      return { ...initialState };

    case DISPLAY_BUILDING_CENTROID[SET]:
      return { ...state, displayBuildingCentroid: payload as boolean };
    default:
      return state;
  }
};

export default plotReducer;

export const getPlotState = (state: RootState): IPlotState => state.plotReducer;
