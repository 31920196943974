import { useAppSelector } from '../../App/store';
import Alert from '../../components/Modals/Alert';
import { getModalsState } from './modalsSlice';

export interface IModalProps {
  open: boolean;
  value?: string;
  callback?: nullable<(sien: string) => void>;
}

const ModalContainer = (): JSX.Element => {
  const { alert } = useAppSelector(getModalsState);

  return (
    <>
      {alert.status && <Alert context={alert.context} modalType={alert.modalType} />}
    </>
  );
};

export default ModalContainer;
