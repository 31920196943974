import {
  InternalError,
  paramsNotFound,
} from '../../../services/axiosFiles/axiosUtils';
import { fetchDatas } from '../../../services/axiosFiles/genericCrud';
import { plotStudiesParser } from '../../study/utils/parsers/studyParser';

export const fetchFolderPlotStudies = async (
  folder: IFolder,
  body: QueryParams,
  users: Users | null,
  statuses: StudyStatuses | null
): Promise<PlotStudies> => {
  try {
    if (folder && users && statuses) {
      const endpoint = folder.idIri + '/plot_studies';
      const result = await fetchDatas(endpoint, body);

      const parsedResponse = plotStudiesParser(
        result['hydra:member'],
        users,
        statuses,
        folder
      );

      return parsedResponse;
    } else {
      return Promise.reject(paramsNotFound());
    }
  } catch (error) {
    return Promise.reject(InternalError(error));
  }
};
