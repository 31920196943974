import { exportExcel } from '../../../../utils/exportExcel';

export const handleExportExcelUserPlotStudies = ({
  plotStudies,
  username,
  plotStudyStatuses,
}: {
  plotStudies: PlotStudies;
  username: string;
  plotStudyStatuses: StudyStatuses | null;
}): void => {
  if (plotStudies && plotStudyStatuses) {
    const worksheetColumns = [
      { header: 'Commune', key: 'city' },
      { header: 'Parcelle', key: 'fullPlotId' },
      { header: 'Surface parcelle', key: 'area' },
      { header: 'Zonage PLU', key: 'zone' },
      { header: 'Zonage PLU enfant', key: 'zoneChild' },
      { header: 'Dossier', key: 'folder' },
      { header: 'Statut de l’opportunité', key: 'status' },
      { header: 'Adresse postale', key: 'mapAddress' },
    ];
    const worksheetRow = plotStudies.map((elt: IPlotStudy) => {
      return {
        ...elt,
        zone: elt.zone ?? ' - ',
        zoneChild: elt.zoneChild ?? ' - ',
        city: elt.city ?? ' - ',
        fullPlotId: elt.plotId ?? ' - ',
        folder: elt.parent?.name ?? ' - ',
        area: elt.area ?? ' - ',
        status: elt.status?.label ?? ' - ',
      };
    });

    exportExcel(`rapport de ${username}`, worksheetColumns, worksheetRow);
  }
};
