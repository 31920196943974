import {
  action,
  CLOSE,
  COLLAPSE,
  createRequestTypes,
  HIDE,
  OPEN,
  SHOW,
  UNCOLLAPSE,
} from '../common/actions';

export const LEFT_PANEL = createRequestTypes('LEFT_PANEL');
export const RIGHT_PANEL = createRequestTypes('RIGHT_PANEL');
export const RIGHT_PANEL_ON_DASHBOARD = createRequestTypes(
  'RIGHT_PANEL_ON_DASHBOARD'
);
export const BUILT_PERMIS_CONTENT = createRequestTypes('BUILT_PERMIS_CONTENT');
export const STUDY_CONTENT = createRequestTypes('STUDY_CONTENT');
export const STUDY_CONTENT_PARAMS = createRequestTypes('STUDY_CONTENT_PARAMS');
export const PANELS_RESET = 'PANELS_RESET';

const panelsActions = {
  leftPanel: {
    uncollapse: () => action(LEFT_PANEL[UNCOLLAPSE], { payload: true }),
    collapse: () => action(LEFT_PANEL[COLLAPSE], { payload: false }),
  },
  rightPanel: {
    open: () => action(RIGHT_PANEL[OPEN], { payload: true }),
    close: () => action(RIGHT_PANEL[CLOSE]),
    closeOnDashboard: () => action(RIGHT_PANEL_ON_DASHBOARD[CLOSE]),
  },
  builtPermitContent: {
    show: () => action(BUILT_PERMIS_CONTENT[SHOW], {}),
    hide: () => action(BUILT_PERMIS_CONTENT[HIDE], {}),
  },
  studyContent: {
    show: (selectedTab: string) => {
      return action(STUDY_CONTENT[SHOW], {
        payload: { rightPanelContent: { selectedTab } },
      });
    },
    hide: () => action(STUDY_CONTENT[HIDE], {}),
  },
  reset: () => action(PANELS_RESET),
};

export default panelsActions;
