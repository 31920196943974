import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import {
  fetchUsersThunk,
  userCreateThunk,
  userPasswordUpdateThunk,
  userUpdateThunk,
} from './usersThunks';

const initialState: UsersState = {
  users: null,
  userCount: 0,
  selectedUser: null,
  isEditingUser: false,
  apiStatus: APIStatus.IDLE,
  apiError: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setEditingUser: (state, action: PayloadAction<boolean>) => {
      state.isEditingUser = action.payload;
    },
    resetApiError: (state) => {
      state.apiStatus = APIStatus.IDLE;
      state.apiError = null;
    },
    setSelectedUser: (state, action: PayloadAction<User>) => {
      state.selectedUser = action.payload;
    },
    resetSelectedUser: (state) => {
      state.selectedUser = null;
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      // FETCH USERS
      .addCase(fetchUsersThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(fetchUsersThunk.fulfilled, (state, action: PayloadAction<any>) => {
        state.apiStatus = APIStatus.IDLE;
        state.apiError = null;
        state.users = action.payload;
        state.userCount = action.payload.length;
      })
      .addCase(fetchUsersThunk.rejected, (state, action: PayloadAction<any>) => {
        state.apiStatus = APIStatus.REJECTED;
        state.users = [];
        state.userCount = 0;
        state.apiError = action.payload;
      })
      // POST USER
      .addCase(userCreateThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(userCreateThunk.fulfilled, (state, action: PayloadAction<User>) => {
        state.apiStatus = APIStatus.IDLE;
        state.apiError = null;
        state.users = state.users
          ? state.users.concat(action.payload)
          : [action.payload];
      })
      .addCase(userCreateThunk.rejected, (state, action: PayloadAction<any>) => {
        state.apiStatus = APIStatus.REJECTED;
        state.apiError = action.payload;
      })
      // UPDATE USER
      .addCase(userUpdateThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(userUpdateThunk.fulfilled, (state, action: PayloadAction<User>) => {
        state.apiStatus = APIStatus.IDLE;
        state.apiError = null;
        state.users = (state.users as Users).map((u) => {
          return u.idIri === action.payload.idIri ? action.payload : u;
        });
      })
      .addCase(userUpdateThunk.rejected, (state, action: PayloadAction<any>) => {
        state.apiStatus = APIStatus.REJECTED;
        state.apiError = action.payload;
      })
      // UPDATE USER PWD
      .addCase(userPasswordUpdateThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(userPasswordUpdateThunk.fulfilled, (state) => {
        state.apiStatus = APIStatus.IDLE;
        state.apiError = null;
      })
      .addCase(
        userPasswordUpdateThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.apiStatus = APIStatus.REJECTED;
          state.apiError = action.payload;
        }
      );
  },
});

export default userSlice.reducer;
export const userActions = userSlice.actions;
export const getUsersState = (state: RootState) => state.users;
