import { isEmpty } from 'lodash';

import { postEntity } from '../../../services/axiosFiles/genericCrud';
import { folderSyntheseParser } from '../folderParser';

export const fetchFolderSynthese = async (plotStudies: PlotStudies) => {
  try {
    if (!isEmpty(plotStudies)) {
      const body = plotStudies?.reduce((obj: any, cur) => {
        obj[cur.fullPlotId] = {
          parcel_id: cur.fullPlotId,
          zipcode: cur.postalCode,
          city_name: cur.city,
          longitude: cur.lng,
          latitude: cur.lat,
        };
        return obj;
      }, {});

      const synthese = await postEntity({
        endpoint: '/get_multi_parcels_datas_from_parcels_ids',
        body,
      });

      const parsedSynthese = folderSyntheseParser(synthese, plotStudies);

      return parsedSynthese;
    } else {
      return null;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
