import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { paramsNotFound } from '../../../../services/axiosFiles/axiosUtils';
import { fetchStudy } from '../../../study/services/fetchStudy';
import { fetchStudyProcedures } from '../../../study/services/fetchStudyProcedures';
import { singlePDFPlotParser } from '../../generatePDFParser';
import { fetchPDFImages } from '../fetchPDFImages';

export const fetchSinglePDFPlotThunk = createAsyncThunk(
  'generatePDF/fetchPDFImages',
  async (params: void, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const { plotDatas, geolocDatas } = state.map;
      const { pluZone } = state.plu;
      const { parcelle } = state.plotReducer;
      const { companyId } = state.company;
      const { study } = state.study;
      const { users } = state.users;
      const { plotStudyStatuses, contactRoles, procedureTypes } = state.app;

      if (
        parcelle &&
        plotDatas?.parcelleLayer?.parcelle &&
        parcelle.parcelleId &&
        users &&
        plotStudyStatuses.result
      ) {
        let studyProcedures: StudyProcedures | null = [];
        if (study.result) {
          studyProcedures = await fetchStudyProcedures(
            study.result?.idIri as string,
            companyId,
            users,
            contactRoles.result,
            procedureTypes.result
          );
        }
        const images = await fetchPDFImages(
          parcelle?.parcelleId,
          plotDatas?.parcelleLayer?.parcelle, // geojson parsed to string
          parcelle.cadastralUrl, // layer cadastral into plot datas
          pluZone.layer // layer plu (into plot datas)
        );

        const parsedResponse = singlePDFPlotParser(
          images,
          parcelle,
          study.result as IPlotStudy,
          geolocDatas?.address ?? '',
          studyProcedures ?? [],
          users,
          plotStudyStatuses.result,
          geolocDatas?.department ?? ''
        );

        return parsedResponse;
      } else {
        return rejectWithValue({
          status: 422,
          message: 'one or more params missing',
        });
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const fetchMultiPDFPlotThunk = createAsyncThunk(
  'generatePDF/fetchMultiPDFPlotThunk',
  async (params: { count: number | null }, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const { folderRightPanelDatas } = state.folders;
    const { companyId } = state.company;
    const { procedureTypes, contactRoles, plotStudyStatuses } = state.app;
    const { users } = state.users;

    try {
      if (params.count !== null) {
        const pdfData = folderRightPanelDatas.result?.pdfTabContent[params.count];

        const plotId = Object.keys(
          folderRightPanelDatas.result?.pdfDataRequest as IPdfDataRequest
        ).find((f) => f.includes(pdfData?.plotId ?? '')) as string;

        const dataRequest = folderRightPanelDatas.result?.pdfDataRequest[plotId];

        const images: {
          cadastrePicture: string;
          zonePicture: string;
        } = await fetchPDFImages(
          plotId,
          dataRequest?.parcel_geometry as string,
          dataRequest?.mapbox_cadastral_url as string,
          dataRequest?.mapbox_zone_url as string
        );

        const pdf = {
          ...pdfData,
          plotImg: images.cadastrePicture,
          zoneImg: images.zonePicture,
        };
        const plotStudy = pdf?.studyIdIri
          ? ((await fetchStudy(
              pdf?.studyIdIri,
              users,
              [],
              plotStudyStatuses.result,
              null
            )) as IPlotStudy)
          : null;

        if (plotStudy) {
          const psProcedures = await fetchStudyProcedures(
            plotStudy.idIri,
            companyId,
            users,
            contactRoles.result,
            procedureTypes.result
          );
          const lp = psProcedures[0];
          const lastProcedure: ILastProcedurePDF | null = lp
            ? {
                responsable: lp.responsable
                  ? `${lp.responsable.firstName} ${lp.responsable.lastName}`
                  : 'Indéfini',
                action: lp.type.label ?? 'indéfini',
                date: lp.date,
                description: lp.comment,
              }
            : null;
          pdf.prospection = {
            ...pdf.prospection,
            constructComment: plotStudy.comment,
            status: plotStudy.status?.label ?? 'Indéfini',
            lastProcedure,
          } as IProspectionPDF;
        } else {
          pdf.prospection = null;
        }

        return pdf as PlotPDFDocument;
      } else {
        return rejectWithValue(paramsNotFound());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
