import { createAsyncThunk } from '@reduxjs/toolkit';
import { FeatureCollection, Polygon } from 'geojson';
import { LatLngTuple } from 'leaflet';
import { isEmpty } from 'lodash';
import { RootState } from '../../App/store';
import { InternalError } from '../../services/axiosFiles/axiosUtils';
import { fetchDatas, postEntity } from '../../services/axiosFiles/genericCrud';
import fetchAssietteSupS from '../../shared/services/servitudes/plot/fetchAssietteSupS';
import fetchInfoPCT from '../../shared/services/servitudes/plot/fetchInfoPCT';
import fetchInfoSurf from '../../shared/services/servitudes/plot/fetchInfoSurf';
import fetchPrescriptionLIN from '../../shared/services/servitudes/plot/fetchPrescriptionLIN';
import fetchPrescriptionPCT from '../../shared/services/servitudes/plot/fetchPrescriptionPCT';
import fetchSCOT from '../../shared/services/servitudes/plot/fetchSCOT';
import { distinctElementsFromArray } from '../../utils/jsFunctions';
import { loadersActions } from '../loaders/loaderSlice';
import {
  externalPdfErrialApiToStoreParser,
  sweepbrightDataApiToStoreParser,
} from './externalPdfErrialParser';
import { externalPdfErrialActions } from './externalPdfErrialSlice';

export const fetchSweepbrightPdfDataThunk = createAsyncThunk(
  'externalPdfErrial/fetchSweepbrightPdfDataThunk',
  async ({ externalId }: { externalId: string }, { rejectWithValue, dispatch }) => {
    try {
      const response: IFetchSweepbrightPdfDataThunkResponse = await fetchDatas(
        `/sweepbright_pdf_data/${externalId}`
      );
      const parsedResponse = sweepbrightDataApiToStoreParser(response.data);

      return parsedResponse;
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);

export const fetchParcelFromAddressAndCityThunk = createAsyncThunk(
  'externalPdfErrial/fetchParcelFromAddressAndCityThunk',
  async (
    params: { postalcode: string; address: string; city: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await postEntity({
        endpoint: `/pdf_urbanease/get_parcel_id_and_area_from_address_and_city/`,
        body: params,
      });

      const parsedResponse = response as IFetchParcelFromAddressAndCityResponse;

      return parsedResponse;
    } catch (error: any) {
      return rejectWithValue(InternalError(error));
    }
  }
);

export const fetchPlotServitudesThunk = createAsyncThunk(
  'externalPdfErrial/fetchPlotServitudesThunk',
  async (
    params: { plotGeometry: Polygon; coordinates: LatLngTuple },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { plotGeometry, coordinates } = params;
      const result = await Promise.all([
        fetchPrescriptionPCT({ plotGeometry }),
        fetchPrescriptionLIN({ plotGeometry }),
        fetchInfoSurf({ plotGeometry }),
        fetchInfoPCT({ plotGeometry }),
        fetchAssietteSupS({ plotGeometry }),
        fetchSCOT({ latLng: coordinates }),
      ]);

      const dataMapParser = (
        data: FeatureCollection,
        type: 'libelle' | 'other' | 'title'
      ) => {
        let multi: string[] = [];
        switch (type) {
          case 'libelle':
            multi = data.features
              .map((f) => f.properties?.libelle ?? f.properties?.nomsuplitt)
              .filter(distinctElementsFromArray);
            break;
          case 'other':
            multi = data.features
              .map((f) => f.properties?.typeass ?? f.properties?.nomsuplitt)
              .filter(distinctElementsFromArray);
            break;
          case 'title':
            multi = data.features ? [data.features[0]?.properties?.title] : [];
            break;
          default:
            break;
        }

        return multi;
      };

      const parsed: OrpiServitudes = {
        ponctPresc: result[0] ? dataMapParser(result[0], 'libelle') : null,
        linPresc: result[1] ? dataMapParser(result[1], 'libelle') : null,
        infoSurf: result[2] ? dataMapParser(result[2], 'libelle') : null,
        infoPonc: result[3] ? dataMapParser(result[3], 'libelle') : null,
        servOth: result[4] ? dataMapParser(result[4], 'other') : null,
        scot: result[5] ? dataMapParser(result[5], 'title') : null,
      };

      dispatch(loadersActions.currentStepSet('Servitudes chargées'));
      return parsed;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchPlotForMultiPlotPdfThunk = createAsyncThunk(
  'multiPlotSelect/fetchPlotForMultiPlotPdfThunk',
  async (
    params: {
      geolocPointInfo: GeolocPointInfo;
      lat: string;
      lng: string;
    },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const existingPlots = state.externalPdfErrial.multiPlotsPdf.result ?? [];
      const goodInsee = existingPlots[0]?.inseeCode ?? '';

      const { lat, lng, geolocPointInfo } = params;

      if (
        existingPlots.length > 0 &&
        goodInsee !== (geolocPointInfo.inseeCode as string)
      ) {
        dispatch(
          externalPdfErrialActions.setInfoModalDisplay({
            display: true,
            plotIds: null,
            errorMessage: null,
            type: 'errorInsee',
          })
        );
        return rejectWithValue({ error: 'invalid insee' });
      } else {
        const response: any = await postEntity({
          endpoint:
            '/pdf_urbanease/get_parcel_id_and_area_from_coordinates_and_insee/',

          body: { insee: geolocPointInfo.inseeCode as string, lat: lat, lon: lng },
        });

        if (!isEmpty(response)) {
          const parsedResponse = {
            ...response,
          } as IFetchParcelFromCoordinatesAndInseeResponse;

          const parsedResult: Omit<IMultiPlotsPdf, 'index'> = {
            fullPlotId: parsedResponse.parcel_id,
            plotId: parsedResponse.parcel_id.substring(5),
            plotGeometry: JSON.parse(parsedResponse.geom),
            buildingsGeometry:
              parsedResponse.buildings &&
              parsedResponse.buildings[0] &&
              parsedResponse.buildings
                .filter((f: any) => f.geom !== null)
                .map((m: any) => JSON.parse(m.geom)),
            lat: parsedResponse.lat,
            lng: parsedResponse.lon,
            areaBuilt: parsedResponse.area_built ?? null,
            areaFree: parsedResponse.area_free ?? null,
            realCapacity: parsedResponse.real_capacity ?? null,
            theoricCapacity: parsedResponse.theoric_capacity ?? null,

            inseeCode: geolocPointInfo.inseeCode as string,
            city: geolocPointInfo.city,
            postalCode: geolocPointInfo.postalCode,
            address: geolocPointInfo.address,
            houseNumber: geolocPointInfo.houseNumber,
            streetName: geolocPointInfo.streetName,
          };

          return parsedResult;
        } else {
          return null;
        }
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchDatasForPdfGenerationThunk = createAsyncThunk(
  'externalPdfErrial/fetchDatasForPdfGenerationThunk',
  async ({ plots }: { plots: string[] }, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await postEntity({
        endpoint: `/pdf_urbanease/get_datas_for_pdf_generation/`,
        body: plots,
      });

      const parsedResponse = externalPdfErrialApiToStoreParser(response);
      dispatch(loadersActions.currentStepSet('Données parcelles chargées'));

      return parsedResponse;
    } catch (error: any) {
      dispatch(
        externalPdfErrialActions.setInfoModalDisplay({
          display: true,
          type: 'errorFetchDatasForPdfGeneration',
          plotIds: null,
          errorMessage: null,
        })
      );
      dispatch(externalPdfErrialActions.setClicked(false));
      return rejectWithValue(InternalError(error));
    }
  }
);
