import { Polygon } from 'geojson';
import commonFetch from './commonFetch';
async function fetchInfoSurf({
  plotGeometry,
}: {
  plotGeometry: Polygon;
  parseForPDF?: boolean;
}) {
  try {
    const result = await commonFetch({
      url: 'https://apicarto.ign.fr/api/gpu/info-surf?',
      plotGeometry,
    });

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default fetchInfoSurf;
