import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import {
  addEntityOrSubEntity,
  deleteEntityOrSubEntity,
  updateEntityOrSubEntity,
} from '../../shared/utils/entityOrSubEntityTools';
import sectorCreateThunk from './services/thunks/sectorCreateThunk';
import sectorDeleteThunk from './services/thunks/sectorDeleteThunk';
import sectorUpdateThunk from './services/thunks/sectorUpdateThunk';
import sectorsFetchThunk from './services/thunks/sectorsFetchThunk';

const initialState: ISectorState = {
  sectors: { apiStatus: APIStatus.IDLE, result: null },
  currentSector: null,
  sectorForAction: null,
  deployedSector: null,
  selectedSectorId: null,
  subSectorParent: null,
  isDrawingSector: false,
  newSectorName: '',
  isEditingSector: null,
};
const sectorSlice = createSlice({
  name: 'sector',
  initialState,
  reducers: {
    editSectorDisplaySet: (state, action: PayloadAction<ISector>) => {
      state.isEditingSector = action.payload;
    },
    editSectorDisplayReset: (state) => {
      state.isEditingSector = null;
    },
    currentSectorSet: (state, action: PayloadAction<ISector>) => {
      state.currentSector = action.payload;
    },
    currentSectorReset: (state) => {
      state.currentSector = null;
    },
    subSectorParentSet: (state, action: PayloadAction<ISector | null>) => {
      state.subSectorParent = action.payload;
    },
    sectorForActionSet: (state, action: PayloadAction<ISector | null>) => {
      state.sectorForAction = action.payload;
    },
    deployedSectorSet: (state, action: PayloadAction<ISector>) => {
      state.deployedSector = action.payload;
    },
    deployedSectorReset: (state) => {
      state.deployedSector = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(sectorsFetchThunk.pending, (state) => {
        state.sectors.apiStatus = APIStatus.PENDING;
        state.sectors.error = undefined;
      })
      .addCase(
        sectorsFetchThunk.fulfilled,
        (state, action: PayloadAction<Sectors>) => {
          state.sectors.apiStatus = APIStatus.IDLE;
          state.sectors.result = action.payload;
          state.sectors.error = undefined;
        }
      )
      .addCase(sectorsFetchThunk.rejected, (state, action: PayloadAction<any>) => {
        state.sectors.apiStatus = APIStatus.IDLE;
        state.sectors.error = action.payload;
      });
    builder
      .addCase(sectorCreateThunk.pending, (state) => {
        state.sectors.apiStatus = APIStatus.PENDING;
        state.sectors.error = undefined;
      })
      .addCase(
        sectorCreateThunk.fulfilled,
        (state, action: PayloadAction<ISector>) => {
          const ap = action.payload;
          state.sectors.apiStatus = APIStatus.IDLE;
          const result = addEntityOrSubEntity({
            entities: state.sectors.result,
            entity: ap,
          });
          state.sectors.result = result as Sectors;

          state.sectors.error = undefined;
          state.subSectorParent = null;
        }
      )
      .addCase(sectorCreateThunk.rejected, (state, action: PayloadAction<any>) => {
        state.sectors.apiStatus = APIStatus.IDLE;
        state.sectors.error = action.payload;
      });
    builder
      .addCase(sectorUpdateThunk.pending, (state) => {
        state.sectors.apiStatus = APIStatus.PENDING;
        state.sectors.error = undefined;
      })
      .addCase(
        sectorUpdateThunk.fulfilled,
        (state, action: PayloadAction<ISector>) => {
          const ap = action.payload;
          state.sectors.apiStatus = APIStatus.IDLE;
          const result = updateEntityOrSubEntity({
            entities: state.sectors.result,
            entity: ap,
          });
          state.sectors.result = result as Sectors;

          state.sectors.error = undefined;
          state.currentSector = null;
          state.isEditingSector = null;
        }
      )
      .addCase(sectorUpdateThunk.rejected, (state, action: PayloadAction<any>) => {
        state.sectors.apiStatus = APIStatus.IDLE;
        state.sectors.error = action.payload;
      });
    builder
      .addCase(sectorDeleteThunk.pending, (state) => {
        state.sectors.apiStatus = APIStatus.PENDING;
        state.sectors.error = undefined;
      })
      .addCase(
        sectorDeleteThunk.fulfilled,
        (state, action: PayloadAction<ISector>) => {
          state.sectors.error = undefined;
          state.sectorForAction = null;
          state.sectors.result = deleteEntityOrSubEntity({
            entities: state.sectors.result,
            entity: action.payload,
          }) as Sectors;
        }
      )
      .addCase(sectorDeleteThunk.rejected, (state, action: PayloadAction<any>) => {
        state.sectors.apiStatus = APIStatus.IDLE;
        state.sectors.error = action.payload;
      });
  },
});

export default sectorSlice.reducer;
export const sectorActions = sectorSlice.actions;
export const getSectorState = (state: RootState) => state.sectors;
