import { combineReducers } from 'redux';
import appReducer from '../../App/appSlice';
import advancedPlotSearchReducer from '../../features/advancedPlotSearch/advancedPlotSearchSlice';
import announcementReducer from '../../features/announcement/announcementSlice';
import authReducer from '../../features/auth/authSlice';
import autocompleteReducer from '../../features/autocomplete/autocompleteSlice';
import buildPermitsReducer from '../../features/buildPermits/buildPermitsSlice';
import brownfieldsReducer from '../../features/brownfields/brownfieldsSlice';
import dpeReducer from '../../features/builtCaracteristics/builtCaracteristicsSlice';
import companyReducer from '../../features/company/companySlice';
import ownerDatasReducer from '../../features/dashboardOwnerDatas/dashboardOwnerDatasSlice';
import dashboardReportingReducer from '../../features/dashboardReporting/dashboardReportingSlice';
import displayManagerReducer from '../../features/displayManager/displayManagerSlice';
import dvfReducer from '../../features/dvf/dvfSlice';
import envReducer from '../../features/envs/envSlice';
import externalPdfErrialReducer from '../../features/externalPdfErrial/externalPdfErrialSlice';
import foldersReducer from '../../features/folders/foldersSlice';
import generatePDFReducer from '../../features/generatePDF/generatePDFSlice';
import georisqueReducer from '../../features/georisques/georisqueSlice';
import loadersReducer from '../../features/loaders/loaderSlice';
import mapReducer from '../../features/map/mapSlice';
import mapPluginsReducer from '../../features/map/plugins/mapPluginSlice';
import modalsReducer from '../../features/modals/modalsSlice';
import nationalServitudeReducer from '../../features/nationalServitudes/nationalServitudesSlice';
import multiPlotSelectReducer from '../../features/plot/multiPlotSelectFeature/multiPlotSelectSlice';
import plotServitudesRedcuer from '../../features/plotServitudes/plotServitudesSlice';
import pluReducer from '../../features/plu/pluSlice';
import proceduresReducer from '../../features/procedures/proceduresSlice';
import sectorsReducerNew from '../../features/sectors/sectorSlice';
import studyParamsReducer from '../../features/study/slices/studyParamsSlice';
import studyReducer from '../../features/study/slices/studySlice';
import urbaGptReducer from '../../features/urbaGpt/urbaGptSlice';
import usersReducer from '../../features/users/usersSlice';
import rightPanelReducer from '../../redux/Panels/reducer';
import contactReducer from '../../redux/contacts/reducer';
import favoritePlacesReducer from '../../redux/favoritePlaces/reducer';
import plotReducer from '../../redux/plot/reducer';

const combinedReducer = combineReducers({
  announcement: announcementReducer,
  app: appReducer,
  auth: authReducer,
  company: companyReducer,
  autocomplete: autocompleteReducer,
  dvf: dvfReducer,
  buildPermits: buildPermitsReducer,
  dpe: dpeReducer,
  brownfields: brownfieldsReducer,
  ownerDatas: ownerDatasReducer,
  plotServitudes: plotServitudesRedcuer,
  envs: envReducer,
  plu: pluReducer,
  procedures: proceduresReducer,
  folders: foldersReducer,
  nationalServitudes: nationalServitudeReducer,
  multiPlotSelect: multiPlotSelectReducer,
  georisque: georisqueReducer,
  modals: modalsReducer,
  advancedPlotSearch: advancedPlotSearchReducer,
  generatePDF: generatePDFReducer,
  dashboardReporting: dashboardReportingReducer,
  urbaGpt: urbaGptReducer,
  study: studyReducer,
  studyParams: studyParamsReducer,
  displayManager: displayManagerReducer,
  contactReducer,
  plotReducer,
  map: mapReducer,
  mapPlugins: mapPluginsReducer,
  sectors: sectorsReducerNew,
  rightPanelReducer,
  users: usersReducer,
  favoritePlacesReducer,
  externalPdfErrial: externalPdfErrialReducer,
  loaders: loadersReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'app/resetAll') {
    state = undefined;
  }

  //only auth and company state are not reseted
  if (action.type === 'app/changeCompany') {
    state = { auth: state.auth, company: state.company };
  }
  return combinedReducer(state, action);
};

export default rootReducer;
