import {
  action,
  createRequestTypes,
  FAILURE,
  REQUEST,
  RESET,
  SET,
  SUCCESS,
} from '../common/actions';

export const FETCH_CONTACTS = createRequestTypes('FETCH_CONTACTS');
export const FETCH_PLOT_STUDIES_BY_CONTACTS = createRequestTypes(
  'FETCH_PLOT_STUDIES_BY_CONTACTS'
);
export const DELETE_CONTACT = createRequestTypes('DELETE_CONTACT');
export const CONTACT_FOR_ACTION = createRequestTypes('CONTACT_FOR_ACTION');
export const CONTACTS_RESET = 'CONTACT_RESET';

const contactActions = {
  fetchAll: {
    request: (criterias: string | undefined) =>
      action(FETCH_CONTACTS[REQUEST], {
        payload: criterias,
      }),
    success: (data: IContact[], qty: number) =>
      action(FETCH_CONTACTS[SUCCESS], {
        payload: { contacts: data, totalContacts: qty },
      }),
    failure: (error: any) => action(FETCH_CONTACTS[FAILURE], { payload: error }),
    reset: () =>
      action(FETCH_CONTACTS[RESET], {
        payload: { contacts: [], totalContacts: 0 },
      }),
  },
  fetchPlotStudiesByContact: {
    request: (
      contactIdIri: string,
      callback: (plotStudies: IPlotStudy[], totalPlotStudies: number) => void
    ) =>
      action(FETCH_PLOT_STUDIES_BY_CONTACTS[REQUEST], {
        payload: { contactIdIri, callback },
      }),
    success: (data: IPlotStudy[], totalItems: number) => {
      return action(FETCH_PLOT_STUDIES_BY_CONTACTS[SUCCESS], {
        payload: { contactPlotStudies: data, totalPlotStudies: totalItems },
      });
    },
    failure: (error: any) => {
      return action(FETCH_PLOT_STUDIES_BY_CONTACTS[FAILURE], { payload: error });
    },
    reset: () => action(FETCH_PLOT_STUDIES_BY_CONTACTS[RESET]),
  },
  delete: {
    request: (endpoint: string) => action(DELETE_CONTACT[REQUEST], { endpoint }),
    success: (data: IContact[]) => {
      return action(DELETE_CONTACT[SUCCESS], {
        payload: { contacts: data },
      });
    },
    failure: (error: any) => {
      return action(DELETE_CONTACT[FAILURE], { payload: error });
    },
  },
  contactForAction: {
    set: (contact: IContact) =>
      action(CONTACT_FOR_ACTION[SET], {
        payload: { contactForAction: contact },
      }),
    reset: () =>
      action(CONTACT_FOR_ACTION[SET], {
        payload: { contactForAction: null },
      }),
  },
  reset: () => action(CONTACTS_RESET),
};

export default contactActions;
