import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDataById, postEntity } from '../../services/axiosFiles/genericCrud';
import { companyApiToStoreParser } from './companyParser';
import { RootState } from '../../App/store';

export const fetchCompanyThunk = createAsyncThunk(
  'company/fetchCompanyThunk',
  async (params: { companyIdIri: string }, { rejectWithValue }) => {
    if (params.companyIdIri) {
      try {
        const result = await fetchDataById(params.companyIdIri);

        const parsed = companyApiToStoreParser(result);

        return parsed;
      } catch (error) {
        return rejectWithValue(error);
      }
    } else {
      return rejectWithValue(new Error('no companyIdIri found'));
    }
  }
);

export const changeCompanyThunk = createAsyncThunk(
  'company/changeCompanyThunk',
  async (
    params: { company: TokenCompany | null },
    { dispatch, rejectWithValue }
  ) => {
    return new Promise<TokenCompany>((resolve, reject) => {
      try {
        if (params.company) {
          dispatch({ type: 'app/changeCompany' });

          return resolve(params.company);
        } else {
          throw rejectWithValue('no param');
        }
      } catch (error) {
        throw rejectWithValue(error);
      }
    });
  }
);

export const setAuthenticatedCompanyThunk = createAsyncThunk(
  'company/setAuthenticatedCompanyThunk',
  async ({ companyId }: { companyId: number }, { rejectWithValue, getState }) => {
    if (companyId) {
      try {
        const state = getState() as RootState;
        const userIdIri = state.auth.userId;

        await postEntity({
          endpoint: '/set_authenticated_company',
          body: {
            company_id: companyId,
            user_id: userIdIri,
          },
        });
      } catch (error) {
        return rejectWithValue(error);
      }
    } else {
      return rejectWithValue(new Error('no companyId found'));
    }
  }
);
