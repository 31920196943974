import { RootState } from '../../App/store';
// import { CHANGE_COMPANY } from '../auth/actions';
import {
  CLOSE,
  COLLAPSE,
  HIDE,
  OPEN,
  SET,
  SHOW,
  UNCOLLAPSE,
} from '../common/actions';
import {
  BUILT_PERMIS_CONTENT,
  LEFT_PANEL,
  PANELS_RESET,
  RIGHT_PANEL,
  RIGHT_PANEL_ON_DASHBOARD,
  STUDY_CONTENT,
  STUDY_CONTENT_PARAMS,
} from './actions';

interface IPanelState {
  rightPanelDisplay?: boolean;
  rightButtons?: boolean;
  leftPanelDisplay?: boolean;
  rightPanelContent: IRightPanelContentState;
}

const initialState: IPanelState = {
  leftPanelDisplay: true,
  rightButtons: true,
  rightPanelDisplay: false,
  rightPanelContent: {
    builtPermisTable: false,
    prospectionContent: false,
    selectedTab: '',
  },
};

export default function rightPanelReducer(
  state: IPanelState = initialState,
  { type, payload }: IAction<string, IPanelState>
): IPanelState {
  switch (type) {
    case RIGHT_PANEL[OPEN]:
      return { ...state, rightPanelDisplay: true };

    case RIGHT_PANEL[CLOSE]:
      return { ...initialState, leftPanelDisplay: false, rightButtons: true };

    case BUILT_PERMIS_CONTENT[SHOW]:
      return {
        ...state,
        leftPanelDisplay: false,
        rightButtons: false,
        rightPanelDisplay: true,
        rightPanelContent: {
          builtPermisTable: true,
          prospectionContent: false,
          selectedTab: '',
        },
      };

    case STUDY_CONTENT[SHOW]:
      return {
        ...state,
        leftPanelDisplay: false,
        rightButtons: false,
        rightPanelDisplay: true,
        rightPanelContent: {
          builtPermisTable: false,
          prospectionContent: true,
          selectedTab: payload.rightPanelContent?.selectedTab,
        },
      };

    case STUDY_CONTENT_PARAMS[SET]:
      return {
        ...state,
        leftPanelDisplay: false,
        rightButtons: false,
        rightPanelDisplay: true,
        rightPanelContent: {
          ...state.rightPanelContent,
        },
      };

    case RIGHT_PANEL_ON_DASHBOARD[CLOSE]:
      return { ...initialState };

    case STUDY_CONTENT[HIDE]:
    case BUILT_PERMIS_CONTENT[HIDE]:
      return {
        ...state,
        leftPanelDisplay: false,
        rightButtons: true,
        rightPanelDisplay: false,
        rightPanelContent: {
          builtPermisTable: false,
          prospectionContent: false,
          selectedTab: '',
        },
      };
    case LEFT_PANEL[COLLAPSE]:
      return {
        ...state,
        leftPanelDisplay: false,
      };

    case LEFT_PANEL[UNCOLLAPSE]:
      return { ...initialState };
    case PANELS_RESET:
      return { ...initialState };
    default:
      return state;
  }
}

export const getPanelState = (state: RootState): IPanelState =>
  state.rightPanelReducer;
export const getRightPanelContentState = (
  state: RootState
): IRightPanelContentState => state.rightPanelReducer.rightPanelContent;
