import { createAsyncThunk } from '@reduxjs/toolkit';
import { InternalError } from '../../services/axiosFiles/axiosUtils';
import { fetchDatas } from '../../services/axiosFiles/genericCrud';
import {
  pluOthersApiToStoreParser,
  zonesAndZoneChildsApiToStore,
} from './pluParser';

export const fetchZonesAndZoneChildsThunk = createAsyncThunk(
  'plu/fetchZonesThunk',
  async (
    params: { city: string | null; postalCode: string | null },
    { rejectWithValue }
  ) => {
    const { city, postalCode } = params;
    try {
      if (city && postalCode) {
        const promise = fetchDatas(
          '/result_get_plu_zone_and_zone_child_list_from_city',
          {
            city_name: city,
            city_zipcode: postalCode,
          }
        );

        return promise.then(
          (response) => {
            const parsedDatas = zonesAndZoneChildsApiToStore(response);

            return parsedDatas;
          },
          (err) => {
            return rejectWithValue(err);
          }
        );
      } else {
        return rejectWithValue({
          status: 0,
          message: 'city or postalCode param missing',
        });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);

export const fetchPluOthersThunk = createAsyncThunk(
  'plu/fetchPluOthersThunk',
  async (
    params: { city: string | null; postalCode: string | null },
    { rejectWithValue }
  ) => {
    const { city, postalCode } = params;
    try {
      if (city && postalCode) {
        const promise = fetchDatas('/get_plu_others_list_from_city', {
          city_name: city,
          city_zipcode: postalCode,
        });

        return promise.then(
          (response) => {
            const parsedDatas = pluOthersApiToStoreParser(response.plu_others_list);

            return parsedDatas;
          },
          (err) => {
            return rejectWithValue(err);
          }
        );
      } else {
        return rejectWithValue({
          status: 0,
          message: 'city or postalCode param missing',
        });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);
