import { Polygon } from 'geojson';
import { paramsNotFound } from '../../../../services/axiosFiles/axiosUtils';
import { fetchDatasExtended } from '../../../../services/axiosFiles/genericCrud';
async function commonFetch({
  url,
  plotGeometry,
}: {
  plotGeometry: Polygon;
  url: string;
  parseForPDF?: boolean;
}) {
  try {
    if (url && plotGeometry) {
      const result = await fetchDatasExtended(url, {
        params: { geom: plotGeometry },
        timeout: 10000,
      });

      return result;
    } else {
      return Promise.reject(paramsNotFound(url));
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export default commonFetch;
