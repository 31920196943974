import React from 'react';
import { Helmet } from 'react-helmet';
import createInputTabsCtx from '../App/contexts/InputTabsContext';
import { DEFAULT_META_DESCRIPTION, DEFAULT_META_KEYWORDS } from './constants';

interface ILayoutProps {
  children: React.ReactNode;
}

const [ctx, InputTabsProvider] = createInputTabsCtx();
export const InputTabsContext = ctx;

const index = ({ children }: ILayoutProps) => {
  return (
    <div>
      <Helmet>
        <title>Urbanease, la recherche foncière</title>
        <meta name="description" content={DEFAULT_META_DESCRIPTION} />
        <meta name="keywords" content={DEFAULT_META_KEYWORDS} />
      </Helmet>

      <InputTabsProvider>
        <main>{children}</main>
      </InputTabsProvider>
    </div>
  );
};

export default index;
