import { isEmpty } from 'lodash';
import { pluCategories } from '../../constants/pluCategories';
import { documentsBuilder } from '../../lib/plotDataFormating';
import {
  changeApiKeyForStoreKey,
  changeStoreKeyForApiKey,
  mapObjectParser,
} from '../common/parserCommon';

const pointConnectedResultMapping: IMapping = {
  building_permit: 'buildingPermit',
  fiscal_law: 'fiscalLaw',
  owners_list: 'ownersList',
  parcel_datas: 'parcelDatas',
  parcel_details: 'parcelDetails',
  parcel_founded: 'parcelFounded',
  taxes: 'taxes',
  transactions: 'transactions',
  zone: 'zone',
};

const pointConnectedResultOwnerListMapping: IMapping = {
  adress: 'address',
  area: 'area',
  corporation: 'corporation',
  legal_status: 'legalStatus',
  more_informations: 'moreInformations',
  nature_culture: 'natureCulture',
  owner: 'owner',
  ownerName: 'ownerName',
  siren: 'siren',
  type: 'type',
  bodacc_details: 'bodaccDetails',
  bodacc_situation: 'bodaccSituation',
  bodacc_termination_date: 'bodaccTerminationDate',
};

const pointConnectedResultParcelDetailsMapping: IMapping = {
  1: 'seismicData',
  2: 'priorityQuarter',
  3: 'historicBuilt',
  4: 'ppri', // Plan de Prevention des Risques d'inondations
  5: 'clayData',
  6: 'pprn', // Plan de Prevention des Risques Naturels
  7: 'peb', // plan d'exposition au bruit
  8: 'others',
};

const parcelleDatasMapping: IMapping = {
  parcel_id: 'parcelleId',
  prefix: 'prefix',
  section: 'section',
  number: 'number',
  insee_city_code: 'inseeCode',
  area_built: 'areaBuilt',
  area_free: 'areaFree',
  date_created: 'dateCreated',
  date_updated: 'dateUpdated',
  real_capacity: 'realCapacity',
  theoric_capacity: 'theoricCapacity',
  type: 'type',
};

const centroidMapping: IMapping = {
  coordinate_x: 'longitude',
  coordinate_y: 'latitude',
};

const parcelDatasBuildingsMapping: IMapping = {
  batiment_area: 'area',
  batiment_created: 'createdAt',
  batiment_name: 'name',
  batiment_type: 'type',
  batiment_updated: 'updatedAt',
  building_centroid_lat: 'centroidLat',
  building_centroid_lon: 'centroidLng',
  building_height_in_metters: 'height',
  building_houses_count: 'houseCount',
  building_stages_count: 'stageCount',
  building_type: 'buildingType',
  building_use_1: 'use1',
  building_use_2: 'use2',
  maximum_ground_elevation: 'groundElevationMax',
  maximum_roof_elevation: 'roofElevationMax',
  minimum_ground_elevation: 'groundElevationMin',
  minimum_roof_elevation: 'roofElevationMin',
  annee_construction_batiment: 'anneeConstructionBatiment',
  nb_lot_garpark: 'nbLotGarpark',
  nb_lot_total: 'nbLotTotal',
  nb_lot_tertiaire: 'nbLotTertiaire',
  nb_logements: 'nbLogements',
  nom_copro: 'nomCopro',
};

const parcelDetailsKeyMapping: IMapping = {
  cat_code: 'catCode',
  cat_libelle: 'catLibelle',
  id: 'id',
  libelle: 'libelle',
  mapbox_url: 'mapboxUrl',
};

const resultTaxesMapping: IMapping = {
  concatener: 'city',
  concatener_2: 'departmentCity',
  nb_commune: 'nbCommune',
  numero: 'numero',
  secteur: 'secteur',
  taux: 'taux',
};

const transactionsMapping: IMapping = {
  adresse_number: 'addressNumber',
  adress_road_name: 'addressRoadName',
  area: 'area',
  city_name: 'cityName',
  land_area: 'landArea',
  land_value: 'landValue',
  local_type: 'localType',
  mutation_date: 'mutationDate',
  parcel_id: 'parcelleId',
  postal_code: 'postalCode',
  rooms_count: 'cntRoom',
  selected_parcel: 'selectedParcelle',
  square_meter_price: 'sqmPrice',
  lat: 'lat',
  lon: 'lng',
};

const zoneMapping: IMapping = {
  fichier: 'file',
  libelle: 'libelle',
  // mapbox_url: 'mapboxUrl',
  zone: 'zone',
  zone_child: 'zoneChild',
};

const parcelSearchFormToApiMapping: IMapping = {
  city_name: 'city',
  city_zipcode: 'postalCode',
  zone: 'zone',
  zone_child: 'zoneChild',
  surface_total_min: 'minParcelle',
  surface_total_max: 'maxParcelle',
  surface_free_min: 'minGround',
  surface_free_max: 'maxGround',
  lots_count: 'lotsCount',
  lots_size: 'lotsSize',
  plu_others_to_exclude: 'pluOtherToExclude',
  polygon: 'polygon',
  has_drawing_done: 'isDrawingZone',
  plot_type: 'plotType',
  owner_name: 'ownerName',
  siren: 'sirenNumber',
  is_bodacc: 'isBodacc',
};

const fromParcelleIdApiToStoreMapping: IMapping = {
  commune_id: 'communeId',
  commune_name: 'communeName',
  documents: 'documents',
  mapbox_cadastral_url: 'mapboxCadastralUrl',
  parcel_id: 'parcelleId',
  point: 'point',
  result: 'result',
  zipcode: 'postalCode',
};

const townHallMapping: IMapping = {
  contact: 'contact',
  lat: 'lat',
  lon: 'lng',
  mayor_first_name: 'mayorFirstName',
  mayor_name: 'mayorLastName',
  mayor_mendate_start_date: 'mayorMandatStartDate',
  phone: 'phone',
  website: 'website',
  population: 'population',
  city_area_in_km2: 'cityArea',
  total_parcels_city: 'plotCount',
  total_building_city: 'buildingCount',
};

const balanceBankAccountMapping = {
  bank_accounts_total: 'totalBankAccounts',
  credit_total: 'totalCredit',
  debit_total: 'totalDebit',
  result: 'result',
  resultat: 'resultat',
};

const pluStatusMapping = {
  city_name: 'city',
  insee: 'insee',
  status: 'status',
};

// Owner list parsers
const ownerListParser = (data: any): IOwner =>
  changeApiKeyForStoreKey(data, pointConnectedResultOwnerListMapping);
export const ownersListParser = (data: any): IOwner[] =>
  mapObjectParser(data, ownerListParser);

// Building Datas parsers
const buildingParser = (data: any): IBuilding => {
  const parsedData: IBuilding = changeApiKeyForStoreKey(
    data,
    parcelDatasBuildingsMapping
  );
  if (parsedData.nomCopro) {
    // Remove the leading number and colon
    const removedNumberColon = parsedData.nomCopro.replace(/^\(\d+:/, '');
    // Remove the parentheses at the beginning and end
    const removedParentheses = removedNumberColon.slice(0, -1);
    // Add spaces after commas
    const resultString = removedParentheses.replace(/,/g, ', ');
    parsedData.nomCopro = resultString;
  }

  return parsedData;
};

const buildingsParser = (data: any): IBuilding[] =>
  mapObjectParser(data, buildingParser);

// BuidingsInfosDataParser
// Parcel Details parsers
const parcelDetailsKeyParser = (data: any) =>
  changeApiKeyForStoreKey(data, parcelDetailsKeyMapping);
const parcelDetailsKeysParser = (data: any) =>
  mapObjectParser(data, parcelDetailsKeyParser);

const parcelDetailsParser = (data: any) => {
  const parsedData: any = changeApiKeyForStoreKey(
    data,
    pointConnectedResultParcelDetailsMapping
  );

  Object.keys(parsedData).forEach((key) => {
    parsedData[key] = parcelDetailsKeysParser(parsedData[key]);
  });

  return parsedData;
};

// Taxes parser
const taxeParser = (data: any): ITaxes =>
  changeApiKeyForStoreKey(data, resultTaxesMapping);
export const taxesParser = (data: any): ITaxes[] =>
  mapObjectParser(data, taxeParser);

// transactions parser
const transactionParser = (data: any): ITransaction => {
  const parsedData = changeApiKeyForStoreKey<any>(data, transactionsMapping);
  parsedData.lots = [];

  for (let index = 1; index <= 5; index++) {
    if (data[`lot${index}_numero`] !== '') {
      parsedData.lots.push(data[`lot${index}_numero`]);
    }
  }

  return parsedData as ITransaction;
};

export const transactionsParser = (data: any): ITransaction[] =>
  mapObjectParser(data, transactionParser);

// zone parser
const zoneParser = (data: any): IZone => changeApiKeyForStoreKey(data, zoneMapping);

const pluStatusParser = (data: { [x: string]: string }) => {
  const parsedPluStatus: IPluStatus = changeApiKeyForStoreKey(
    data,
    pluStatusMapping
  );

  parsedPluStatus.status =
    parsedPluStatus.status === 'CC' ? 'Carte Communale' : parsedPluStatus.status;

  return parsedPluStatus;
};

export const townHallParser = (data: {
  [x: string]: number | string;
}): ITownHall => {
  const NC = 'Non renseigné';
  let preparsedData: any = {};

  const keys = Object.keys(data);
  keys.forEach((key) => {
    preparsedData[key] = data[key] === NC ? null : data[key];
  });

  preparsedData = {
    ...preparsedData,
    population: preparsedData.population ?? parseInt(preparsedData.population, 10),
    city_area_in_km2:
      preparsedData.city_area_in_km2 ??
      parseFloat(parseFloat(preparsedData.city_area_in_km2).toFixed(2)),
    total_building_city:
      preparsedData.total_building_city ??
      parseInt(preparsedData.total_building_city, 10),
    total_parcels_city:
      preparsedData.total_parcels_city ??
      parseInt(preparsedData.total_parcels_city, 10),
  };

  const parsedData = changeApiKeyForStoreKey<ITownHall>(
    preparsedData,
    townHallMapping
  );

  return parsedData;
};

const parcelleMapBuilder = (data: any): PlotDatasTemp => {
  return {
    markerLatLng: [
      parseFloat(data.result.parcel_datas.parcel_centroid_lat),
      parseFloat(data.result.parcel_datas.parcel_centroid_lon),
    ],
    address: null,
    townHall: {
      latLng: [
        parseFloat(data.result.town_hall_contact.lat),
        parseFloat(data.result.town_hall_contact.lon),
      ],
    },
    inseeCode: data.result.parcel_datas.insee_city_code,
    houseNumber: null,
    streetName: null,
    parcelleLayer: {
      parcelle: data.result.parcel_datas.parcel_geometry,
      buildings: data.result.parcel_datas.buildings[0].batiment_wkb_geometry
        ? data.result.parcel_datas.buildings?.map(
            (b: any) => b.batiment_wkb_geometry
          )
        : null,
    },
  };
};

export const parcelEnvBuilder = (
  data: {
    cat: string;
    data: { [x: string]: string | number }[];
  }[]
): IParcelleEnv[] => {
  const envs = data.map((d: any) => {
    return {
      ...pluCategories[d.cat - 1],
      state: d.datas.map((m: { [x: string]: string | number }) => m.libelle),
    };
  });

  return envs;
};

const balanceBankDetailsParser = (datas: any) => {
  const parsedData = changeApiKeyForStoreKey<IBalanceBankAccount>(
    datas,
    balanceBankAccountMapping
  );

  return parsedData;
};

const parcelleBuilder = (data: any): IPlot => {
  const parcelDatas: any = data.result.parcel_datas;
  const impotEmails: string[] = data.contact_impots;
  const pluStatus: IPluStatus | null = isEmpty(data.result.plu_status)
    ? null
    : pluStatusParser(data.result.plu_status);

  const parsedParcelDatas = changeApiKeyForStoreKey<any>(
    parcelDatas,
    parcelleDatasMapping
  );

  // **PLU_ENVIRONMENT**
  // Catégorie 1 : Données sismiques
  // Catégorie 2 : Quartiers prioritaires
  // Catégorie 3 : Monuments historiques
  // Catégorie 4 : PPRI - Zones inondables
  // Catégorie 5 : Argiles Datas
  // Catégorie 6 : PPRN
  // Catégorie 7 : Exposition au bruit
  const parcelEnv = parcelEnvBuilder(data.result.parcel_details);

  const documents = documentsBuilder(data.result.documents);

  const balanceBankAccount = balanceBankDetailsParser(
    data.result.balance_banks_account
  );

  const fiscalLaw: {
    eligibility: string;
    zone: string;
  } = {
    eligibility: data.result.fiscal_law.eligibility,
    zone: data.result.fiscal_law.zone,
  };

  const ownerList: {
    state: string;
    list: IOwner[];
  } = {
    state: data.result.owners_list.state,
    list: ownersListParser(data.result.owners_list.datas),
  };

  let buildings: nullable<IBuilding[]> = null;
  if (data.result.parcel_datas.buildings[0].batiment_wkb_geometry) {
    buildings = buildingsParser(data.result.parcel_datas.buildings);
  }

  const taxes: ITaxes[] = taxesParser(data.result.taxes);
  const zone: IZone = zoneParser(data.result.zone);

  const townHall: ITownHall = townHallParser(data.result.town_hall_contact);

  const transactions: ITransaction[] = transactionsParser(data.result.transactions);

  return {
    ...parsedParcelDatas,
    documents,
    fiscalLaw,
    ownerList,
    buildings,
    taxes,
    zone,
    townHall,
    transactions,
    parcelEnv,
    impotEmails,
    balanceBankAccount,
    pluStatus,
    cadastralUrl: data.mapbox_cadastral_url,
  };
};
// ***********************************
// *** POINT CONNECTED PARSER
// ***********************************

export const pointConnectedParser = (
  data: any
): {
  parcelle: IPlot;
  map: PlotDatasTemp;
  layers: IPointConnectedLayers;
} => {
  try {
    const parsedData: {
      parcelle: IPlot;
      map: PlotDatasTemp;
      layers: IPointConnectedLayers;
    } = {
      parcelle: parcelleBuilder(data),
      map: parcelleMapBuilder(data),
      layers: {
        pluUrl: data.result.zone.mapbox_url,
      },
    };

    return parsedData;
  } catch (error) {
    throw new Error('parsing probleme pointConnectedParser');
  }
};

export const parcelsSearchFormToApiParser = (data: any) => {
  const parsedData = changeStoreKeyForApiKey(data, parcelSearchFormToApiMapping);

  return parsedData;
};

export function parcelleIdApiToStore(data: any) {
  const parsedData: any = changeApiKeyForStoreKey(
    data,
    fromParcelleIdApiToStoreMapping
  );

  parsedData.result = changeApiKeyForStoreKey(
    data.result,
    pointConnectedResultMapping
  );

  parsedData.ownersList = ownersListParser(data.result.owners_list.datas);

  parsedData.datas = changeApiKeyForStoreKey(
    data.result.parcel_datas,
    parcelleDatasMapping
  );

  parsedData.datas.centroid = changeApiKeyForStoreKey(
    data.result.parcel_datas.centroid,
    centroidMapping
  );

  parsedData.parcelFounded = data.result.parcel_founded;

  parsedData.datas.buildings = buildingsParser(data.result.parcel_datas.buildings);

  parsedData.details = parcelDetailsParser(data.result.parcel_details);

  parsedData.taxes = taxesParser(data.result.taxes);

  parsedData.transactions = transactionsParser(data.result.transactions);

  if (data.result.zone) {
    parsedData.zone = zoneParser(data.result.zone);
  }

  return parsedData;
}
