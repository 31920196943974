import { cloneDeep } from 'lodash';

export const folderMarkersColor = [
  '#10B2F7',
  '#1664DB',
  '#798E8A',
  '#E2DB13',
  '#E38F14',
  '#ED24F1',
  '#E21313',
  '#50D313',
  '#13E2BD',
  '#944BF2',
];

export const affectPlotStudiesToFolderAndSub = (
  folder: IFolder,
  plotStudiesTab: PlotStudies[]
) => {
  const clonedFolder = cloneDeep(folder);
  clonedFolder.plotStudies = plotStudiesTab[0];

  if (clonedFolder.subs && clonedFolder.subCount > 0) {
    for (let i = 0; i < clonedFolder.subCount; i++) {
      const elt = clonedFolder.subs[i];
      elt.plotStudies = plotStudiesTab[i + 1];
    }
  }

  return clonedFolder;
};

export const envParse = (data: nullable<string[]>): IEnvPDFDisplay => {
  return data ? { indice: data[0], libelle: data[1] } : { libelle: '-' };
};

export const envPdfParser = (env: IParcelleEnv[], cat: number) => {
  return env
    .find((e) => e.cat === cat)
    ?.state[0].split('-')
    .map((m) => m.trim());
};

export const checkDeleteFolder = (folders: Folders, folder: IFolder): boolean => {
  if (folder.plotStudiesCount === 0 && folder.subCount === 0) {
    return true;
  } else {
    return false;
  }
};
