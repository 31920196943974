export const hasAllChildChecked = (servitude: Servitude): boolean => {
  if (servitude.childs) {
    return servitude.childs.every((f) => {
      return f.checked === true && hasAllChildChecked(f);
    });
  } else {
    return servitude.checked;
  }
};

export const checkRecursive = (
  servitudeState: Servitude,
  servitudeAction: Servitude,
  value: boolean,
  type: 'check' | 'isOpen'
): Servitude => {
  const updatedState = { ...servitudeState };

  const recursiveOpen = (tab: Servitudes | null): Servitudes | null =>
    tab
      ? tab.map((m) => {
          return type === 'isOpen'
            ? {
                ...m,
                isOpen: value,
                // childs: recursiveOpen(m.childs),
              }
            : {
                ...m,
                // isOpen: value,
                checked: value,
                childs: recursiveOpen(m.childs),
              };
        })
      : null;

  const checkServitude = (servitude: Servitude) => {
    if (servitude.label === servitudeAction.label) {
      if (type === 'isOpen') {
        servitude.isOpen = value;
      } else {
        servitude.isOpen = value;
        servitude.checked = value;
        servitude.childs = recursiveOpen(servitude.childs);
      }
    } else {
      servitude.childs?.forEach((m) => checkServitude(m)) ?? null;
    }

    const allChildUnchecked = servitude.childs
      ? servitude.childs?.every((e) => e.checked === false)
      : false;

    if (servitude.checked && allChildUnchecked) {
      servitude.checked = false;
    }
  };

  checkServitude(updatedState);

  return updatedState as Servitude;
};

export const displayedLayers = (
  servitude: Servitude,
  result: string[]
): number | void =>
  hasAllChildChecked(servitude)
    ? result.push(servitude.layer)
    : servitude.childs?.forEach((m) => displayedLayers(m, result));
