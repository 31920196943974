import { Polygon } from 'geojson';
import commonFetch from './commonFetch';
async function fetchPrescriptionLIN({ plotGeometry }: { plotGeometry: Polygon }) {
  try {
    const result = await commonFetch({
      url: 'https://apicarto.ign.fr/api/gpu/prescription-lin?',
      plotGeometry,
    });

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default fetchPrescriptionLIN;
