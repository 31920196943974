import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../App/store';

import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import {
  constructStudyDatasThunk,
  createPlotStudyThunk,
  deleteStudyThunk,
  fetchStudyThunk,
  updateStudyThunk,
} from '../services/thunks/studyThunks';
import {
  RecursiveSubFolderStatusEnum,
  RecursiveSubFolderUpdatedElement,
} from '../utils/studyEnums';

const initialState: StudyState = {
  study: { apiStatus: APIStatus.IDLE, result: null },
  studyPlotStudies: { apiStatus: APIStatus.IDLE, result: null },
  studyPlotStudiesDisplay: false,
  updateFolder: {
    updatedElement: RecursiveSubFolderUpdatedElement.NULL,
    recursiveSubFolderUpdateStatus: RecursiveSubFolderStatusEnum.NULL,
  },
  actionState: { status: APIStatus.IDLE, error: null },
};

const studySlice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    studySet: (state, action: PayloadAction<IStudy>) => {
      state.study.result = action.payload;
    },
    studyPlotStudiesDisplay: (state, action: PayloadAction<boolean>) => {
      state.studyPlotStudiesDisplay = action.payload;
    },
    setRecursiveSubFolderUpdateStatus: (
      state,
      action: PayloadAction<RecursiveSubFolderStatusEnum>
    ) => {
      state.updateFolder.recursiveSubFolderUpdateStatus = action.payload;
    },
    setRecursiveSubFolderUpdatedElement: (
      state,
      action: PayloadAction<RecursiveSubFolderUpdatedElement>
    ) => {
      state.updateFolder.updatedElement = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(constructStudyDatasThunk.pending, (state) => {
      state.study.apiStatus = APIStatus.PENDING;
      state.study.result = null;
    });
    builder.addCase(
      constructStudyDatasThunk.fulfilled,
      (state, action: PayloadAction<ConstructStudyRequestResult>) => {
        const ap = action.payload;
        state.study.apiStatus = APIStatus.IDLE;
        state.study.result = ap.study;
        state.studyPlotStudies.result = ap.studyPlotStudies;
      }
    );
    builder.addCase(
      constructStudyDatasThunk.rejected,
      (state, action: PayloadAction<any>) => {
        state.study.apiStatus = APIStatus.REJECTED;
        state.study.result = null;
        state.study.error = action.payload;
      }
    );
    builder
      .addCase(fetchStudyThunk.pending, (state) => {
        state.study.apiStatus = APIStatus.PENDING;
        state.study.result = null;
        state.study.error = undefined;
      })
      .addCase(fetchStudyThunk.fulfilled, (state, action: PayloadAction<IStudy>) => {
        state.study.apiStatus = APIStatus.IDLE;
        state.study.result = action.payload;
        state.study.error = undefined;
      })
      .addCase(fetchStudyThunk.rejected, (state, action: PayloadAction<any>) => {
        state.study.apiStatus = APIStatus.REJECTED;
        state.study.result = null;
        state.study.error = action.payload;
      });
    builder
      .addCase(createPlotStudyThunk.pending, (state) => {
        state.study.apiStatus = APIStatus.PENDING;
        state.study.result = null;
        state.study.error = undefined;
      })
      .addCase(
        createPlotStudyThunk.fulfilled,
        (state, action: PayloadAction<IStudy>) => {
          state.study.apiStatus = APIStatus.IDLE;
          state.study.result = action.payload;
          state.study.error = undefined;
        }
      )
      .addCase(
        createPlotStudyThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.study.apiStatus = APIStatus.REJECTED;
          state.study.result = null;
          state.study.error = action.payload;
        }
      );
    builder
      .addCase(updateStudyThunk.pending, (state) => {
        state.study.apiStatus = APIStatus.PENDING;
        state.study.error = undefined;
      })
      .addCase(
        updateStudyThunk.fulfilled,
        (state, action: PayloadAction<IStudy | null>) => {
          state.study.apiStatus = APIStatus.IDLE;
          state.study.result = action.payload ?? state.study.result;
          state.updateFolder = {
            recursiveSubFolderUpdateStatus: RecursiveSubFolderStatusEnum.NULL,
            updatedElement: RecursiveSubFolderUpdatedElement.NULL,
          };
          state.study.error = undefined;
        }
      )
      .addCase(updateStudyThunk.rejected, (state, action: PayloadAction<any>) => {
        state.study.apiStatus = APIStatus.REJECTED;
        state.study.error = action.payload;
      });

    builder
      .addCase(deleteStudyThunk.pending, (state) => {
        state.study.apiStatus = APIStatus.PENDING;
        state.study.error = undefined;
      })
      .addCase(deleteStudyThunk.fulfilled, (state) => {
        state.study.apiStatus = APIStatus.IDLE;
        state.study.result = null;
        state.study.error = undefined;
      })
      .addCase(deleteStudyThunk.rejected, (state, action: PayloadAction<any>) => {
        state.study.apiStatus = APIStatus.REJECTED;
        state.study.error = action.payload;
      });
  },
});

export default studySlice.reducer;
export const studyActions = studySlice.actions;
export const getStudyState = (state: RootState) => state.study;
