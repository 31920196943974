import { Polygon } from 'geojson';
import commonFetch from './commonFetch';
async function fetchPrescriptionPCT({ plotGeometry }: { plotGeometry: Polygon }) {
  try {
    const result = await commonFetch({
      url: 'https://apicarto.ign.fr/api/gpu/prescription-pct?',
      plotGeometry,
    });

    return result;
  } catch (error) {
    return null;
  }
}

export default fetchPrescriptionPCT;
