import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { fetchDatas } from '../../../services/axiosFiles/genericCrud';
import { studyProceduresApiToStoreParser } from '../utils/parsers/studyProcedureParser';

export const fetchStudyProcedures = async (
  studyIdIri: string | null,
  companyId: number | null,
  users: Users | null,
  contactRoles: ContactRoles | null,
  procedureTypes: ProcedureTypes | null
) => {
  try {
    if (studyIdIri && companyId && users && contactRoles && procedureTypes) {
      const result = await fetchDatas(`${studyIdIri}/procedures`, {
        company_id: companyId,
      });

      if (result && result['hydra:member']) {
        const parsedResult = studyProceduresApiToStoreParser(
          result['hydra:member'],
          users,
          contactRoles,
          procedureTypes
        );

        return parsedResult;
      } else return [];
    } else {
      return Promise.reject(paramsNotFound('fetchStudyProcedures'));
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
