import { Polygon } from 'geojson';
import commonFetch from './commonFetch';
async function fetchInfoPCT({
  plotGeometry,
}: {
  plotGeometry: Polygon;
  parseForPDF?: boolean;
}) {
  try {
    const result = await commonFetch({
      url: 'https://apicarto.ign.fr/api/gpu/info-pct?',
      plotGeometry,
    });

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default fetchInfoPCT;
