import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { paramsNotFound } from '../../../../services/axiosFiles/axiosUtils';
import { fetchDatas } from '../../../../services/axiosFiles/genericCrud';
import { sectorsParser } from '../../sectorParser';

const sectorsFetchThunk = createAsyncThunk(
  'sectors/sectorsFetchThunk',
  async (params: {}, thunkOptions) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const { companyIdIri } = state.company;
      const { users } = state.users;
      if (users && companyIdIri) {
        const result = await fetchDatas(`${companyIdIri}/sectors`);

        const parsed = sectorsParser(result['hydra:member'], users);

        return parsed;
      } else {
        return thunkOptions.rejectWithValue(paramsNotFound('sectorsFetchThunk'));
      }
    } catch (error) {
      return thunkOptions.rejectWithValue(error);
    }
  }
);

export default sectorsFetchThunk;
