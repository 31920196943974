import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import { fetchPluOthersThunk, fetchZonesAndZoneChildsThunk } from './pluThunk';

const initialState: PluState = {
  activeInsee: null,
  zones: null,
  zoneChilds: null,
  pluZone: { layer: null, displayed: false, noUrl: false },
  pluOthers: null,
  apiError: null,
  apiStatus: APIStatus.IDLE,
  updateInseeLoader: false,
};

const pluSlice = createSlice({
  name: 'plu',
  initialState,
  reducers: {
    checkLocationInsee: (state, action: PayloadAction<string>) => {
      state.updateInseeLoader = state.activeInsee !== action.payload;
    },
    InseeLoaderReset: (state) => {
      state.updateInseeLoader = false;
    },
    setActiveInsee: (state, action: PayloadAction<string>) => {
      state.activeInsee = action.payload;
    },
    pluLayerShow: (state) => {
      state.pluZone.displayed = true;
    },
    pluLayerHide: (state) => {
      state.pluZone.displayed = false;
    },
    pluLayerSet: (
      state,
      action: PayloadAction<{ pluUrl: string | null; insee: string }>
    ) => {
      const url = action.payload.pluUrl === '' ? null : action.payload.pluUrl;
      if (
        (state.pluZone.layer === null &&
          action.payload.insee === state.activeInsee) ||
        action.payload.insee !== state.activeInsee
      ) {
        state.pluZone.layer = url;
        state.activeInsee = action.payload.insee;
        state.pluZone.displayed = false;
        state.pluZone.noUrl = !url;
      }
      state.updateInseeLoader = false;
    },
    pluLayerReset: (state) => {
      state.pluZone.layer = null;
      state.pluZone.displayed = false;
      state.updateInseeLoader = false;
    },
    pluOtherDisplay: (state, action: PayloadAction<string>) => {
      const ap = action.payload;
      state.pluOthers =
        state.pluOthers?.map((e) => {
          return e.libelle === ap ? { ...e, displayed: !e.displayed } : e;
        }) ?? null;
    },
    apiStatusReset: (state) => {
      state.apiError = null;
      state.apiStatus = APIStatus.IDLE;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchZonesAndZoneChildsThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchZonesAndZoneChildsThunk.fulfilled,
        (state, action: PayloadAction<Pick<PluState, 'zones' | 'zoneChilds'>>) => {
          const ap = action.payload;
          state.apiStatus = APIStatus.IDLE;
          state.zones = ap.zones;
          state.apiError = null;
          state.zoneChilds = ap.zoneChilds;
        }
      )
      .addCase(
        fetchZonesAndZoneChildsThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.apiStatus = APIStatus.REJECTED;
          state.apiError = action.payload;
        }
      )
      .addCase(fetchPluOthersThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchPluOthersThunk.fulfilled,
        (state, action: PayloadAction<PluOthers>) => {
          const ap = action.payload;
          state.apiStatus = APIStatus.IDLE;
          state.apiError = null;
          state.pluOthers = ap;
        }
      )
      .addCase(fetchPluOthersThunk.rejected, (state, action: PayloadAction<any>) => {
        state.apiStatus = APIStatus.REJECTED;
        state.apiError = action.payload;
      }),
});

export default pluSlice.reducer;
export const pluActions = pluSlice.actions;
export const getPluState = (state: RootState) => state.plu;
