import { Route, Switch } from 'react-router-dom';
import Layout from '../layout';
import PasswordResetPage from '../pages/PasswordResetPage';
import ModalContainer from '../features/modals/ModalContainer';
import HomePage from '../pages/HomePage';

const App = () => {
  return (
    <div className="app">
      <Layout>
        <ModalContainer />
        <Switch>
          <Route path="/" exact={true} component={HomePage} />
          <Route path="/reset_password" exact={true} component={PasswordResetPage} />
        </Switch>
      </Layout>
    </div>
  );
};

export default App;
