import { getCityCode } from '../lib/inseeCodeFilter';
import AddressServerService from '../shared/services/AddressServerService';
import GoogleService from '../shared/services/GoogleService';
import { fetchInseeFromLatLngDep } from '../shared/services/fetchInseeCode';

export const addrServProcessAsync = async (
  lat: number,
  lng: number
): Promise<GeolocPointInfo | null> => {
  try {
    let servAddr: GeolocPointInfo | null = null;
    try {
      servAddr = await AddressServerService.getLocationByLatLngAsync(lat, lng);
    } catch (error) {
      servAddr = null;
    }

    if (!servAddr) {
      servAddr = await GoogleService.getLocationByLatLngAsync(lat, lng);
    }

    // if no insee (google result), fetch inseCode to python
    if (servAddr && !servAddr.inseeCode) {
      const result = await fetchInseeFromLatLngDep(
        servAddr?.coordinates[0],
        servAddr?.coordinates[1],
        servAddr?.department
      );
      servAddr.inseeCode = result.inseeCode;
    }

    if (servAddr && servAddr.inseeCode) {
      // assign global inseeCode for some cities
      servAddr.inseeCodeDistrict = servAddr.inseeCode;
      servAddr.inseeCode = getCityCode(servAddr.inseeCode);
    }

    return servAddr ?? null;
  } catch (error: any) {
    throw new Error(error?.message ?? 'fetch address error');
  }
};
