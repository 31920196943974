import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import { fetchBrownfieldsThunk } from './brownFieldsThunk';

const initialState: BrownfieldsState = {
  brownfields: { apiStatus: APIStatus.IDLE, result: null },
  brownfieldsVisible: false,
};

const brownfieldsSlice = createSlice({
  name: 'brownfields',
  initialState,
  reducers: {
    setBaseData: (
      state,
      action: PayloadAction<{ inseeCode: string | null; postalCode: string | null }>
    ) => ({
      ...initialState,
      postalCode: action.payload.postalCode,
      inseeCode: action.payload.inseeCode,
    }),

    setDisplayBrownfields: (state, action: PayloadAction<boolean>) => {
      state.brownfieldsVisible = action.payload;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrownfieldsThunk.pending, (state) => {
        state.brownfields.apiStatus = APIStatus.PENDING;
        state.brownfields.result = null;
        state.brownfields.error = undefined;
      })
      .addCase(
        fetchBrownfieldsThunk.fulfilled,
        (state, action: PayloadAction<Brownfields>) => {
          state.brownfields.apiStatus = APIStatus.IDLE;
          state.brownfields.error = undefined;
          state.brownfields.result = action.payload;
        }
      )
      .addCase(
        fetchBrownfieldsThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.brownfields.apiStatus = APIStatus.REJECTED;
          state.brownfields.error = action.payload;
        }
      );
  },
});

export default brownfieldsSlice.reducer;
export const brownfieldsActions = brownfieldsSlice.actions;
export const getBrownfieldsState = (state: RootState) => state.brownfields;
