import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { RootState } from '../../../App/store';
import AltimetryActionEnum from './altimetryPlugin/types/altimetryActionEnum';
import { DrawActionEnum, DrawIconEnum } from './drawPlugin/types/drawActionEnum';
import { MeasureActionEnum } from './measurePlugin/types/measureActionEnum';
import { ToolbarTypeEnum } from './urbaToolbar/types/ToolbarBtnEnums';

const urbaToolbarInit: ToolbarState = {
  displayedToolbar: [
    ToolbarTypeEnum.MEASURE,
    ToolbarTypeEnum.ALTIMETRY,
    ToolbarTypeEnum.MULTISELECT,
  ],

  activeToolbar: null,
};
const rightToolbarInit: RightToolbarState = { displayed: true };
const drawInit: DrawState = {
  action: null,
  geometry: null,
  displayedIcons: [],
};
const measureInit: MeasureState = {
  action: null,
  displayInterface: false,
  lineActivated: false,
  datas: [],
};

const altimetryInit: AltimetryState = {
  action: null,
};

const initialState: MapPluginsState = {
  rightToolbar: rightToolbarInit,
  urbaToolbar: urbaToolbarInit,
  draw: drawInit,
  measure: measureInit,
  altimetry: altimetryInit,
};
const mapPluginsSlice = createSlice({
  name: 'mapPlugins',
  initialState,
  reducers: {
    reset: () => initialState,
    resetToolbar: (state) => {
      state.urbaToolbar = urbaToolbarInit;
    },
    activeToolbarSet: (state, action: PayloadAction<ToolbarTypeEnum>) => {
      state.urbaToolbar.activeToolbar = action.payload;
      state.urbaToolbar.displayedToolbar = state.urbaToolbar.displayedToolbar.filter(
        (f) => f === action.payload
      );
      if (action.payload === ToolbarTypeEnum.MULTISELECT) {
        // state.
      }
    },
    resetDraw: (state) => {
      state.draw = drawInit;
      state.urbaToolbar = urbaToolbarInit;
    },
    resetMeasure: (state) => {
      state.measure = measureInit;
      state.urbaToolbar = urbaToolbarInit;
    },
    resetAltimetry: (state) => {
      state.altimetry = altimetryInit;
      state.urbaToolbar = urbaToolbarInit;
    },
    drawStart: (state, action: PayloadAction<DrawActionEnum>) => {
      if (!state.urbaToolbar.activeToolbar) {
        state.urbaToolbar.activeToolbar = ToolbarTypeEnum.DRAW;
        state.urbaToolbar.displayedToolbar = [ToolbarTypeEnum.DRAW];

        if (action.payload === DrawActionEnum.DRAW_SECTOR) {
          state.draw.action = action.payload;
          state.draw.displayedIcons = [DrawIconEnum.DRAW_ICON_STOP];
        }
      }
      if (state.urbaToolbar.activeToolbar === ToolbarTypeEnum.DRAW) {
        // drawbar is active because advanced search checkbox is checked
        if (state.draw.action === DrawActionEnum.DRAW_ADVANCED_SEARCH) {
          state.draw.action = DrawActionEnum.DRAW_ADVANCED_SEARCH_START;
        }
      }
    },
    drawStop: (state) => {
      if (state.urbaToolbar.activeToolbar === ToolbarTypeEnum.DRAW) {
        // this condition because altimetry use leaflet.draw
        // and DRAW_STOP reset layers
        state.draw.displayedIcons = [];
        if (state.draw.action === DrawActionEnum.DRAW_ADVANCED_SEARCH_START) {
          state.draw.action = DrawActionEnum.DRAW_ADVANCED_SEARCH_SAVE_GEO;
        } else {
          state.draw.action = DrawActionEnum.DRAW_STOP;
        }
      }
    },
    edit: (state) => {
      if (!state.draw.action && !state.urbaToolbar.activeToolbar) {
        state.urbaToolbar.activeToolbar = ToolbarTypeEnum.DRAW;
        state.urbaToolbar.displayedToolbar = [ToolbarTypeEnum.DRAW];
        state.draw.action = DrawActionEnum.EDIT;
      }
    },
    drawActionSet: (state, action: PayloadAction<DrawActionEnum>) => {
      if (state.urbaToolbar.activeToolbar === ToolbarTypeEnum.DRAW) {
        state.draw.action = action.payload;
      }
    },
    drawAdvancedSearch: (state) => {
      if (!state.draw.action && !state.urbaToolbar.activeToolbar) {
        state.draw.action = DrawActionEnum.DRAW_ADVANCED_SEARCH;
        state.urbaToolbar.activeToolbar = ToolbarTypeEnum.DRAW;
        state.urbaToolbar.displayedToolbar = [ToolbarTypeEnum.DRAW];
      }
    },
    drawGeometrySet: (state, action: PayloadAction<FeatureCollection | null>) => {
      if (state.draw.action) {
        state.draw.geometry = action.payload;
        state.draw.action =
          state.draw.action === DrawActionEnum.DRAW_SECTOR
            ? DrawActionEnum.DRAW_SAVE
            : state.draw.action === DrawActionEnum.DRAW_ADVANCED_SEARCH_START
            ? DrawActionEnum.DRAW_ADVANCED_SEARCH_SAVE_GEO
            : state.draw.action;
      }
    },
    // measure actions
    measureInterfaceDisplaySet: (state, action: PayloadAction<boolean>) => {
      const sm = state.measure;
      state.measure.displayInterface = action.payload;
      if (action.payload) {
        state.urbaToolbar.displayedToolbar = state.urbaToolbar.displayedToolbar.filter(
          (f) => f === ToolbarTypeEnum.MEASURE
        );
        state.urbaToolbar.activeToolbar = ToolbarTypeEnum.MEASURE;
      } else {
        // if close interface, stop all actions and desactivate line draw
        // and init urbatoolbar
        state.measure.action = null;
        state.measure.lineActivated = false;
        state.urbaToolbar = urbaToolbarInit;
      }
    },
    measureActionSet: (state, action: PayloadAction<MeasureActionEnum | null>) => {
      const t = state.urbaToolbar.activeToolbar;
      if (t === ToolbarTypeEnum.MEASURE) {
        state.measure.action = action.payload;
        if (
          state.measure.lineActivated &&
          action.payload === MeasureActionEnum.START_DRAW_SURFACE
        ) {
          state.measure.lineActivated = false;
        }

        if (action.payload === null) {
          state.measure.datas = [];
        }
      }
    },
    measureLineActivate: (state, action: PayloadAction<boolean>) => {
      const t = state.urbaToolbar.activeToolbar;
      if (t === ToolbarTypeEnum.MEASURE) {
        if (!state.measure.action) {
          state.measure.lineActivated = action.payload;
        }
      }
    },
    measureDataAdd: (state, action: PayloadAction<MeasureData>) => {
      state.measure.datas = state.measure.datas.concat(action.payload);
    },
    altimetryClick: (state) => {
      const at = state.urbaToolbar.activeToolbar;
      if (at === null) {
        state.urbaToolbar.activeToolbar = ToolbarTypeEnum.ALTIMETRY;
        state.altimetry.action = AltimetryActionEnum.ALTIMETRY_START;
        state.urbaToolbar.displayedToolbar = state.urbaToolbar.displayedToolbar.filter(
          (f) => f === ToolbarTypeEnum.ALTIMETRY
        );
      }
      if (at === ToolbarTypeEnum.ALTIMETRY) {
        state.altimetry.action = AltimetryActionEnum.ALTIMETRY_STOP;
      }
    },
  },
});

export default mapPluginsSlice.reducer;
export const mapPluginsActions = mapPluginsSlice.actions;
export const getMapPluginsState = (state: RootState) => state.mapPlugins;
export const getMapPluginsUrbaToolbarState = (state: RootState) =>
  state.mapPlugins.urbaToolbar;
export const getMapPluginsDrawState = (state: RootState) => state.mapPlugins.draw;
export const getMapPluginsMeasureState = (state: RootState) =>
  state.mapPlugins.measure;
export const getMapPluginsAltimetryState = (state: RootState) =>
  state.mapPlugins.altimetry;
