import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import arrayFilter from '../../lib/arrayFilter';

const initialState: GeorisqueState = {
  displayedLayers: [],
};
const georisqueSlice = createSlice({
  name: 'georisque',
  initialState,
  reducers: {
    checkAll: (
      state,
      action: PayloadAction<{ layer: string; checked: boolean }>
    ) => {
      const ap = action.payload;

      if (ap.checked && !state.displayedLayers.includes(ap.layer)) {
        state.displayedLayers = state.displayedLayers.concat(ap.layer);
      }
      if (!ap.checked && state.displayedLayers.includes(ap.layer)) {
        state.displayedLayers = state.displayedLayers.filter((f) => f !== ap.layer);
      }
    },
    multiRowCheck: (
      state,
      action: PayloadAction<{ values: string[]; check: boolean }>
    ) => {
      if (action.payload.check) {
        state.displayedLayers = state.displayedLayers.concat(action.payload.values);
      } else {
        state.displayedLayers = state.displayedLayers.filter(
          (f) => !action.payload.values.includes(f)
        );
      }
    },
    checkRow: (state, action: PayloadAction<string>) => {
      state.displayedLayers = arrayFilter(state.displayedLayers, action.payload);
    },
  },
});

export default georisqueSlice.reducer;
export const getGeorisqueState = (state: RootState) => state.georisque;
export const georisquesActions = georisqueSlice.actions;
