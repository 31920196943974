import { createAsyncThunk, nanoid } from '@reduxjs/toolkit';
import { FeatureCollection, Polygon } from 'geojson';
import fetchAssietteSupS from '../../shared/services/servitudes/plot/fetchAssietteSupS';
import fetchInfoPCT from '../../shared/services/servitudes/plot/fetchInfoPCT';
import fetchInfoSurf from '../../shared/services/servitudes/plot/fetchInfoSurf';
import fetchPrescriptionLIN from '../../shared/services/servitudes/plot/fetchPrescriptionLIN';
import fetchPrescriptionPCT from '../../shared/services/servitudes/plot/fetchPrescriptionPCT';
import fetchSCOT from '../../shared/services/servitudes/plot/fetchSCOT';

export const fetchPrescriptionPctThunk = createAsyncThunk(
  'plotServitudes/fetchPrescriptionPct',
  async (params: { geo: Polygon }) => {
    try {
      const result = fetchPrescriptionPCT({
        plotGeometry: params.geo,
      });

      return result;
    } catch (error) {
      return null;
    }
  }
);

export const fetchPrescriptionLinThunk = createAsyncThunk(
  'plotServitudes/fetchPrescriptionLin',
  async (params: { geo: Polygon }) => {
    try {
      const result = fetchPrescriptionLIN({
        plotGeometry: params.geo,
      });

      return result;
    } catch (error) {
      return null;
    }
  }
);

export const fetchInfoSurfThunk = createAsyncThunk(
  'plotServitudes/infoSurf',
  async (params: { geo: Polygon }) => {
    try {
      const result = fetchInfoSurf({
        plotGeometry: params.geo,
      });

      return result;
    } catch (error) {
      return null;
    }
  }
);

export const fetchInfoPctThunk = createAsyncThunk(
  'plotServitudes/infoPct',
  async (params: { geo: Polygon }) => {
    try {
      const result = fetchInfoPCT({
        plotGeometry: params.geo,
      });

      return result;
    } catch (error) {
      return null;
    }
  }
);

export const fetchAssietteSupSThunk = createAsyncThunk(
  'plotServitudes/assietteSupS',
  async (params: { geo: Polygon }) => {
    try {
      const result = fetchAssietteSupS({
        plotGeometry: params.geo,
      });

      return result;
    } catch (error) {
      return null;
    }
  }
);

//www.geoportail-urbanisme.gouv.fr/api/feature-info/scot?lon=-1.474841&lat=43.492949&typeName=scot&zone=production
export const fetchScotThunk = createAsyncThunk(
  'plotServitudes/scot',
  async (params: { lat: number; lng: number }) => {
    try {
      const { lat, lng } = params;
      const result = await fetchSCOT({ latLng: [lat, lng] });

      const parsed = {
        ...result,
        features: (result as FeatureCollection).features.map((f) => ({
          ...f,
          id: nanoid(),
        })),
      };

      return parsed;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
