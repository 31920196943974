import { postEntity } from '../../../services/axiosFiles/genericCrud';

const ENV = process.env.REACT_APP_ENV;

export const fetchPDFImages = async (
  plotId: string,
  parcelle: string,
  cadastralUrl: string | null,
  layer: string | null
) => {
  try {
    const response: any = await postEntity({
      endpoint: '/get_imgs_for_pdf_from_parcels_ids',
      body: {
        [plotId]: {
          parcel_geometry: parcelle,
          mapbox_cadastral_url: cadastralUrl,
          mapbox_zone_url: layer,
        },
      },
      params: null,
      timeout: ENV === 'prod' ? 30000 : 60000,
    });

    return {
      cadastrePicture: response[plotId].parcel_on_cadastre_picture as string,
      zonePicture: response[plotId].parcel_on_zone_picture as string,
    };
  } catch (error: any) {
    return Promise.reject(error);
  }
};
