import {
  changeApiKeyForStoreKey,
  changeStoreKeyForApiKey,
  mapObjectParser,
} from '../common/parserCommon';

export const contactMapping = {
  '@id': 'idIri',
  id: 'id',
  firstName: 'firstName',
  lastName: 'lastName',
  name: 'name',
  phone: 'phone',
  email: 'email',
  role: 'role',
  address: 'address',
  description: 'description',
  city: 'city',
  //zipCode: 'zipCode',
  country: 'country',
  company: 'company',
  // plotStudyContacts: 'plotStudyContacts',
};

export const contactsApiToStoreParser = (data: any): IContact[] => {
  return mapObjectParser(data, contactApiToStoreParser);
};
export const contactApiToStoreParser = (data: any): IContact => {
  const parsedData = changeApiKeyForStoreKey<IContact>(data, contactMapping);

  parsedData.sortValues = { lastName: parsedData.lastName };

  if (!parsedData.firstName && !parsedData.lastName && parsedData.name) {
    parsedData.lastName = parsedData.name;
  }
  return parsedData;
};
export const contactFormToApiParser = (data: Partial<IContact>): any => {
  return changeStoreKeyForApiKey(data, contactMapping);
};
