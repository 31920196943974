import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import { fetchAdvancedPlotSearchThunk } from './advancedPlotSearchThunks';

const initialState: AdvancedPlotSearchState = {
  activeInsee: null,
  geoFromDraw: null,
  formData: null,
  isCentered: false,
  plots: { apiStatus: APIStatus.IDLE, result: null },
  plotCount: 0,
  deployedBloc: { zonage: true, plotCalc: true, builtCalc: true, owner: true },
};
const advancedPlotSearchSlice = createSlice({
  name: 'advancedPlotSearch',
  initialState,

  reducers: {
    reset: () => initialState,
    resetForm: (state) => {
      state.formData = null;
      state.activeInsee = null;
    },
    setIsCentered: (state, action: PayloadAction<boolean>) => {
      state.isCentered = action.payload;
    },
    setActiveInsee: (state, action: PayloadAction<string | null>) => {
      state.activeInsee = action.payload;
    },
    formDataSet: (state, action: PayloadAction<AdvancedPlotSearchForm | null>) => {
      state.formData = action.payload;
    },
    setDisplayBlock: (state, action: PayloadAction<keyof DeployedBlocType>) => {
      const ap = action.payload;
      state.deployedBloc = {
        ...state.deployedBloc,
        [ap]: !state.deployedBloc[ap],
      };
    },
    setGeoFromDraw: (state, action: PayloadAction<FeatureCollection | null>) => {
      state.geoFromDraw = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdvancedPlotSearchThunk.pending, (state) => {
        state.plots.apiStatus = APIStatus.PENDING;
        state.plots.result = null;
        state.plotCount = 0;
        state.plots.error = undefined;
      })
      .addCase(
        fetchAdvancedPlotSearchThunk.fulfilled,
        (state, action: PayloadAction<AdvancedSearchPlots>) => {
          state.plots.apiStatus = APIStatus.IDLE;
          (state.isCentered = false), (state.plots.result = action.payload);
          state.plotCount = action.payload.length;
          state.plots.error = undefined;
        }
      )
      .addCase(
        fetchAdvancedPlotSearchThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.plots.apiStatus = APIStatus.REJECTED;
          state.plots.result = null;
          state.plotCount = 0;
          state.plots.error = action.payload;
        }
      );
  },
});

export default advancedPlotSearchSlice.reducer;
export const advancedPlotSearchActions = advancedPlotSearchSlice.actions;
export const getAdvancedPlotSearchState = (state: RootState) =>
  state.advancedPlotSearch;
