import { RootState } from '../../App/store';
// import { CHANGE_COMPANY } from '../auth/actions';
import { RESET, SET, SUCCESS } from '../common/actions';
import {
  CONTACTS_RESET,
  CONTACT_FOR_ACTION,
  FETCH_CONTACTS,
  FETCH_PLOT_STUDIES_BY_CONTACTS,
} from './action';

const initialState: IContactState = {
  contacts: null,
  totalContacts: 0,
  contactType: null,
  contactForAction: null,
  contactPlotStudies: null,
  totalPlotStudies: 0,
};

export default function (
  state = initialState,
  { type, payload }: IAction<string, IContactState>
): IContactState {
  switch (type) {
    case FETCH_CONTACTS[SUCCESS]:
    case FETCH_CONTACTS[RESET]:
      return {
        ...state,
        contacts: (payload as IContactState).contacts,
        totalContacts: (payload as IContactState).totalContacts,
      };

    case CONTACT_FOR_ACTION[SET]:
      return { ...state, ...payload };

    case CONTACT_FOR_ACTION[RESET]:
      return {
        ...state,
        contactForAction: null,
        contactPlotStudies: null,
        totalPlotStudies: 0,
      };

    case FETCH_PLOT_STUDIES_BY_CONTACTS[SUCCESS]:
      return {
        ...state,
        contactPlotStudies: payload.contactPlotStudies,
        totalPlotStudies: payload.totalPlotStudies,
      };
    case FETCH_PLOT_STUDIES_BY_CONTACTS[RESET]:
      return {
        ...state,
        contactPlotStudies: null,
        totalPlotStudies: 0,
      };
    case CONTACTS_RESET: {
      return { ...initialState };
    }
    default:
      return state;
  }
}

export const getContactState = (state: RootState): IContactState =>
  state.contactReducer;
