import { createContext, useState } from 'react';

export enum searchTypeEnum {
  Adresse,
  Parcelle,
}

export enum predictionOriginEnum {
  ADDRESS_SERVER,
  GOOGLE,
}

export enum PredictionTypeEnum {
  MUNICIPALITY = '0-Municipalité',
  LOCALITY = '1-Lieu dit',
  STREET = '2-Rue',
  TOURIST_ATTRACTION = '3-Attraction touristique',
  OTHERS = '4-Autre',
}

type UpdateStringValue = React.Dispatch<React.SetStateAction<string>>;
type UpdatePredictions = React.Dispatch<React.SetStateAction<PredictionBase>>;

type UpdateBoolean = React.Dispatch<React.SetStateAction<boolean>>;
type UpdateInputTab = React.Dispatch<React.SetStateAction<searchTypeEnum>>;
type UpdateMenuTab = React.Dispatch<React.SetStateAction<string>>;
type VoidFunction = () => void;

export default function createInputTabsCtx() {
  const defaultInputTab = searchTypeEnum.Adresse;
  const defaultMenuTab = 'URBANISME';
  const defaultPlotId = '';
  const defaultInputValue = '';
  const defaultPredictions: PredictionBase = {
    origin: predictionOriginEnum.ADDRESS_SERVER,
    data: [],
  };
  const defaultPredictionDisplay = false;
  const defaultIsEnterClick = false;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const defaultVoidFunction: VoidFunction = () => {};
  const setInputTab: UpdateInputTab = () => defaultInputTab;
  const setMenuTab: UpdateMenuTab = () => defaultMenuTab;
  const setPlotId: UpdateStringValue = () => defaultPlotId;
  const setInputValue: UpdateStringValue = () => defaultInputValue;
  const setPredictions: UpdatePredictions = () => defaultPredictions;
  const setPredictionsDisplay: UpdateBoolean = () => defaultPredictionDisplay;
  const setIsEnterClick: UpdateBoolean = () => defaultIsEnterClick;

  const ctx = createContext({
    inputTab: defaultInputTab,
    setInputTab,
    menuTab: defaultMenuTab,
    setMenuTab,
    plotId: defaultPlotId,
    setPlotId,
    inputValue: defaultInputValue,
    setInputValue,
    predictions: defaultPredictions,
    setPredictions,
    predictionsDisplay: defaultPredictionDisplay,
    setPredictionsDisplay,
    isEnterClick: defaultIsEnterClick,
    setIsEnterClick,
    resetContext: defaultVoidFunction,
  });

  const Provider = (props: React.PropsWithChildren<{}>) => {
    const [inputTab, setInputTab] = useState(searchTypeEnum.Adresse);
    const [menuTab, setMenuTab] = useState(defaultMenuTab);
    const [plotId, setPlotId] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [predictions, setPredictions] = useState<PredictionBase>(
      defaultPredictions
    );
    const [predictionsDisplay, setPredictionsDisplay] = useState<boolean>(false);
    const [isEnterClick, setIsEnterClick] = useState<boolean>(false);

    const resetContext = () => {
      setInputTab(searchTypeEnum.Adresse);
      setMenuTab(defaultMenuTab);
      setPlotId('');
      setInputValue('');
      setPredictions(defaultPredictions);
      setPredictionsDisplay(false);
      setIsEnterClick(false);
    };

    return (
      <ctx.Provider
        value={{
          inputTab,
          setInputTab,
          menuTab,
          setMenuTab,
          plotId,
          setPlotId,
          inputValue,
          setInputValue,
          predictions,
          setPredictions,
          predictionsDisplay,
          setPredictionsDisplay,
          isEnterClick,
          setIsEnterClick,
          resetContext,
        }}
        {...props}
      />
    );
  };

  return [ctx, Provider] as const;
}
