import { createAsyncThunk } from '@reduxjs/toolkit';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import { isEmpty } from 'lodash';
import { RootState } from '../../App/store';
import { pagingTraitment } from '../../lib/axios/pagingTraitment';
import { InternalError, paramsNotFound } from '../../services/axiosFiles/axiosUtils';
import { fetchDatas, updateEntity } from '../../services/axiosFiles/genericCrud';
import { fetchPluZoneFromPlotListSlice } from '../../shared/services/fetchPluZoneFromPlotList';
import entityListFromNestedEntities from '../../utils/entityListFromNestedEntities';
import { loadersActions } from '../loaders/loaderSlice';
import { handleExportExcelUserPlotStudies } from '../plotStudies/userPlotStudies/dashboard/excel';
import { checkAndUpdatePlotStudyDatas } from '../study/services/checkAndUpdatePlotStudyDatas';
import { fetchPlotStudies } from '../study/services/fetchPlotStudies';
import updateStudy from '../study/services/updateStudy';
import addZonesToPlotStudies from '../study/utils/addZonesToPlotStudies';
import {
  individualUserPlotsStatus,
  reportingUsersStatsApiToStoreParser,
} from './dashboardReportingParser';
import { groupBy } from './utils';

export const fetchUsersStatsThunk = createAsyncThunk(
  'reportingUsers/fetchUsersStatsThunk',
  async (
    params: {
      createdAtAfter: string;
      createdAtBefore: string;
    },
    { getState }
  ) => {
    try {
      const state = getState() as RootState;
      const { companyId } = state.company;
      const { createdAtAfter, createdAtBefore } = params;
      const { users } = state.users;

      if (createdAtAfter && createdAtBefore && companyId && users) {
        const response = await fetchDatas(`/companies/${companyId}/users_stats`, {
          'createdAt[before]': createdAtBefore,
          'createdAt[after]': createdAtAfter,
          'order[createdAt]': 'DESC',
          'status.id': [1, 7],
          action: ['auth_success', 'external_connection'],
        });

        const parsed = reportingUsersStatsApiToStoreParser(response, users);
        return parsed;
      } else {
        return Promise.reject(paramsNotFound());
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const fetchReportingIndividualUserThunk = createAsyncThunk(
  'reportingUsers/fetchReportingIndividualUserThunk',
  async (
    { userIdIri, companyId }: { userIdIri: string; companyId: number },
    { getState }
  ) => {
    const state = getState() as RootState;
    const { plotStudyStatuses } = state.app;
    const { users } = state.users;
    const { folders } = state.folders;

    try {
      if (plotStudyStatuses.result && users && folders.result) {
        const dAfter = format(startOfMonth(subMonths(new Date(), 11)), 'yyyy-MM-dd');
        const dBefore = format(endOfMonth(new Date()), 'yyyy-MM-dd');
        entityListFromNestedEntities(folders.result);

        const params: QueryParams = {
          company_id: companyId,
          'createdAt[after]': dAfter,
          'createdAt[before]': dBefore,
        };
        //create a promise and lauch all request at same time?
        const plotStudies = await fetchPlotStudies({
          urlBase: userIdIri,
          params,
          statuses: plotStudyStatuses.result,
          users,
          folders: entityListFromNestedEntities(folders.result) as Folders,
        });

        const zones = await fetchPluZoneFromPlotListSlice(plotStudies);

        const handleUpdatePsZones = async (ps: IPlotStudy) => {
          const body: QueryParams = { zone: ps.zone, zoneChild: ps.zoneChild };
          await updateStudy(ps.idIri, body, null, null, null, null, null, true);
        };
        const extendedPlotStudies = addZonesToPlotStudies(
          plotStudies,
          zones,
          handleUpdatePsZones
        );

        const proceduresReponse = await pagingTraitment({
          endpoint: `${userIdIri}/procedures`,
          params,
        });

        return {
          plotsStatus: await individualUserPlotsStatus(
            extendedPlotStudies,
            plotStudyStatuses.result
          ),
          performanceActivity: {
            plotStudiesCounter: groupBy(extendedPlotStudies, 'createdAt').reverse(),
            proceduresCounter: groupBy(proceduresReponse, 'createdAt').reverse(),
          },
          plots: extendedPlotStudies,
        };
      } else {
        return Promise.reject(paramsNotFound());
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchReportingIndividualUserPlotsExportExcel = createAsyncThunk(
  'reportingUsers/fetchReportingIndividualUserPlotsExportExcel',
  async (
    {
      plotStudies,
      username,
      plotStudyStatuses,
    }: {
      plotStudies: PlotStudies | null;
      username: string;
      plotStudyStatuses: StudyStatuses | null;
    },
    thunkAPI
  ) => {
    try {
      if (plotStudies && username && plotStudyStatuses) {
        thunkAPI.dispatch(loadersActions.loaderShow());
        const responseAddressPlots = await Promise.all(
          plotStudies.map(async (m) => {
            return checkAndUpdatePlotStudyDatas({ ps: m });
          })
        );

        await Promise.all(
          plotStudies.map((elt, index) => {
            if (isEmpty(elt.address)) {
              const payloadUpdate: any = {
                idIri: elt.idIri,
                body: { address: responseAddressPlots[index]?.address },
              };
              return updateEntity(payloadUpdate);
            }
          })
        );

        const plotStudiesParsed = plotStudies.map((elt, index) => {
          return {
            ...elt,
            mapAddress: responseAddressPlots[index]?.address ?? null,
          };
        });

        handleExportExcelUserPlotStudies({
          plotStudies: plotStudiesParsed,
          username,
          plotStudyStatuses,
        });
        thunkAPI.dispatch(loadersActions.loaderHide());
      } else {
        thunkAPI.dispatch(loadersActions.loaderHide());
        return thunkAPI.rejectWithValue(
          paramsNotFound('fetchReportingIndividualUserPlotsExportExcel')
        );
      }
    } catch (error) {
      thunkAPI.dispatch(loadersActions.loaderHide());
      return thunkAPI.rejectWithValue(InternalError(error));
    }
  }
);
