import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { InternalError } from '../../../../services/axiosFiles/axiosUtils';
import fetchFolderAndSubsPlotStudiesForDisplay from '../fetchFolderAndSubsPlotStudiesForDisplay';

export const fetchFolderAndSubsPlotStudiesForDisplayThunk = createAsyncThunk(
  'displayManager/fetchFolderAndSubsPlotStudiesForDisplayThunk',
  async (params: { folder: IFolder | null }, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const { users } = state.users;
      const { plotStudyStatuses } = state.app;

      if (params.folder) {
        const plotStudiesTab = await fetchFolderAndSubsPlotStudiesForDisplay(
          params.folder,
          users ?? [],
          plotStudyStatuses.result ?? []
        );

        return plotStudiesTab;
      } else {
        return rejectWithValue({ status: 0, message: 'folder param missing' });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);
