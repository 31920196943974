import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import { fetchDpeAdvancedSearchPointsThunk, fetchDpePointsThunk } from './dpeThunk';

export function dpeExtraReducers(builder: ActionReducerMapBuilder<DpeState>) {
  builder
    //#region fetch dpePoints
    .addCase(fetchDpePointsThunk.pending, (state) => {
      state.dpePoints.apiStatus = APIStatus.PENDING;
      state.dpePoints.error = undefined;
    })
    .addCase(
      fetchDpePointsThunk.fulfilled,
      (state, action: PayloadAction<DpePoints>) => {
        state.dpePoints.apiStatus = APIStatus.IDLE;
        state.dpePoints.error = undefined;
        state.dpePoints.result = action.payload;
      }
    )
    .addCase(fetchDpePointsThunk.rejected, (state, action: PayloadAction<any>) => {
      state.dpePoints.apiStatus = APIStatus.REJECTED;
      state.dpePoints.error = action.payload;
      state.dpePoints.result = null;
    })
    .addCase(fetchDpeAdvancedSearchPointsThunk.pending, (state) => {
      state.dpeAdvancedSearchPoints.apiStatus = APIStatus.PENDING;
      state.dpeAdvancedSearchPoints.error = undefined;
    })
    .addCase(
      fetchDpeAdvancedSearchPointsThunk.fulfilled,
      (state, action: PayloadAction<DpePoints>) => {
        state.dpeAdvancedSearchPoints.apiStatus = APIStatus.IDLE;
        state.dpeAdvancedSearchPoints.error = undefined;
        state.dpeAdvancedSearchPoints.result = action.payload;
      }
    )
    .addCase(
      fetchDpeAdvancedSearchPointsThunk.rejected,
      (state, action: PayloadAction<any>) => {
        state.dpeAdvancedSearchPoints.apiStatus = APIStatus.REJECTED;
        state.dpeAdvancedSearchPoints.error = action.payload;
        state.dpeAdvancedSearchPoints.result = null;
      }
    );
}
