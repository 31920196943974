import {
  faCheck,
  faExclamation,
  faInfo,
  faXmark,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../../assets/images/logo.png';
import styles from './genericModal.module.scss';

export enum GenericModalActionEnum {
  NONE,
  CROSS,
  OK,
  OKCANCEL,
}

export enum GenericModalEnum {
  INFO,
  CONFIRMATION,
  WARNING,
  ERROR,
}

interface IGenericModalProps {
  actionType: GenericModalActionEnum;
  modalType: GenericModalEnum;
  title: string;
  children: React.ReactNode;
  closeModal: () => void;
  okButtonContent?: string;
  cancelButtonContent?: string;
  okCallback?: () => void;
  cancelCallback?: () => void;
  hasIcon?: boolean;
  icon?: string;
  hasLogo?: boolean;
  hasExtendWidth?: boolean;
  suppressionConfirm?: boolean;
}

const getCssTheme = (
  type: GenericModalEnum
): { styles: string; icon: IconDefinition } => {
  switch (type) {
    case GenericModalEnum.CONFIRMATION:
      return { styles: styles.confirmationTheme, icon: faCheck };
    case GenericModalEnum.WARNING:
      return { styles: styles.warningTheme, icon: faExclamation };
    case GenericModalEnum.ERROR:
      return { styles: styles.errorTheme, icon: faExclamation };
    case GenericModalEnum.INFO:
    default:
      return { styles: styles.infoTheme, icon: faInfo };
  }
};

function GenericModal(props: IGenericModalProps) {
  const {
    okButtonContent,
    cancelButtonContent,
    actionType,
    modalType,
    okCallback,
    cancelCallback,
    closeModal,
    title,
    children,
    hasIcon = true,
    hasLogo,
    hasExtendWidth,
    suppressionConfirm,
  } = props;

  const handleCancelClick = () => {
    if (cancelCallback) {
      cancelCallback();
    } else {
      closeModal();
    }
  };

  const handleOkClick = () => {
    if (okCallback) {
      okCallback();
    } else {
      closeModal();
    }
  };

  return (
    <div className={styles.genericModalBackground}>
      <div
        className={`${styles.genericModal} ${getCssTheme(modalType).styles}  ${
          hasExtendWidth ? styles.extendWidth : ''
        }`}
      >
        <div className={styles.modalContainer}>
          {actionType !== GenericModalActionEnum.OK &&
            actionType !== GenericModalActionEnum.OKCANCEL && (
              <div className={styles.topIcon} onClick={closeModal}>
                <FontAwesomeIcon icon={faXmark} />
              </div>
            )}
          {hasLogo && <img src={logo} alt="logo urbanease" />}
          <h2>{title}</h2>

          <div className={styles.content}>
            {hasIcon && (
              <div className={styles.iconContainer}>
                <FontAwesomeIcon icon={getCssTheme(modalType).icon} />
              </div>
            )}
            <div className={styles.body}>{children}</div>
          </div>

          {actionType !== GenericModalActionEnum.NONE && (
            <div className={styles.buttonGrp}>
              <div className={styles.buttonOk} onClick={handleOkClick}>
                {okButtonContent}
              </div>
              {actionType === GenericModalActionEnum.OKCANCEL && (
                <div className={styles.buttonCancel} onClick={handleCancelClick}>
                  {cancelButtonContent ?? 'Annuler'}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default GenericModal;
