import { createAsyncThunk } from '@reduxjs/toolkit';
import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { postEntity } from '../../../services/axiosFiles/genericCrud';
import { dpePointsParser, dpePopupContentParser, dpesParser } from './dpeParser';
import { format } from 'date-fns';

const DATA_URL = process.env.REACT_APP_API_URL_DATAS;

export const fetchDpePointsThunk = createAsyncThunk(
  'dpe/fetchDpePointsThunk',
  async ({ insee }: { insee: string | null }, { rejectWithValue }) => {
    try {
      if (insee) {
        const response = await postEntity({
          endpoint: `${DATA_URL}/get_dpe_points_from_city/`,
          body: {
            insee: insee,
          },
        });

        const parsedResponse = dpePointsParser(response as any);

        return parsedResponse;
      } else {
        return rejectWithValue(paramsNotFound('fetchDpePointsThunk'));
      }
    } catch (error: any) {
      if (error.message === 'parser') {
        return rejectWithValue({ status: 0, message: 'parser error', error });
      }
      return rejectWithValue(error);
    }
  }
);

export const fetchDpePopupContentThunk = createAsyncThunk(
  'dpe/fetchDpePopup',
  async (params: { address: string }) => {
    const { address } = params;
    const response = postEntity({
      endpoint: `${DATA_URL}/get_dpe_datas_from_geo_adresse/`,
      body: {
        geo_adresse: address,
      },
    });

    return response
      .then((response) => {
        const parsedResponse = dpesParser(response as any);

        const popup = dpePopupContentParser(parsedResponse);

        return popup;
      })
      .catch((err) => {
        return undefined as never;
      });
  }
);

export const fetchDpeAdvancedSearchPointsThunk = createAsyncThunk(
  'dpe/fetchDpeAdvancedSearchPointsThunk',
  async (
    {
      insee,
      builtAreaMin,
      builtAreaMax,
      dpeMin,
      dpeMax,
      gesMin,
      gesMax,
      buildingType,
      createdAtAfter,
      createdAtBefore,
    }: {
      insee: string;
      builtAreaMin: number;
      builtAreaMax: number;
      dpeMin: string;
      dpeMax: string;
      gesMin: string;
      gesMax: string;
      buildingType: string;
      createdAtAfter: Date | null;
      createdAtBefore: Date | null;
    },
    { rejectWithValue }
  ) => {
    try {
      if (insee) {
        const body = {
          insee: insee,
          date_min: createdAtAfter
            ? format(createdAtAfter as Date, 'yyyy-MM-dd')
            : null,
          date_max: createdAtBefore
            ? format(createdAtBefore as Date, 'yyyy-MM-dd')
            : null,
          built_area_min: builtAreaMin,
          built_area_max: builtAreaMax,
          dpe_min: dpeMin,
          dpe_max: dpeMax,
          ges_min: gesMin,
          ges_max: gesMax,
          building_type: buildingType,
        };

        // new object excluding keys with false values
        const filteredBody = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value)
        );

        const response = await postEntity({
          endpoint: `${DATA_URL}/get_dpe_points_from_city/`,
          body: filteredBody,
        });

        const parsedResponse = dpePointsParser(response as any);

        return parsedResponse;
      } else {
        return rejectWithValue(paramsNotFound('fetchDpePointsThunk'));
      }
    } catch (error: any) {
      if (error.message === 'parser') {
        return rejectWithValue({ status: 0, message: 'parser error', error });
      }
      return rejectWithValue(error);
    }
  }
);
