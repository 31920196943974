import { LatLngTuple } from 'leaflet';
import { isEmpty } from 'lodash';
import { call, put, select, take } from 'redux-saga/effects';
import { getAppState } from '../../App/appSlice';
import { GenericModalEnum } from '../../components/Modals/GenericModal';
import { handleExportCityBankAccounts } from '../../features/advancedPlotSearch/exportExcel/excel';
import { authActions } from '../../features/auth/authSlice';
import { autocompleteActions } from '../../features/autocomplete/autocompleteSlice';
import { getCompanyState } from '../../features/company/companySlice';
import { getFoldersState } from '../../features/folders/foldersSlice';
import { getMapState, mapActions } from '../../features/map/mapSlice';
import { modalsActions } from '../../features/modals/modalsSlice';
import { pluActions } from '../../features/plu/pluSlice';
import { studyParamsActions } from '../../features/study/slices/studyParamsSlice';
import { plotStudiesParser } from '../../features/study/utils/parsers/studyParser';
import { getUsersState } from '../../features/users/usersSlice';
import { parserService } from '../../services/ParserService';
import { fetchDataById, fetchDatas } from '../../services/axiosFiles/genericCrud';
import { genericPagingTraitment } from '../../services/axiosFiles/methods';
import { addrServProcessAsync } from '../../utils/addrServProcessAsync';
import panelsActions from '../Panels/actions';
import { getPanelState } from '../Panels/reducer';
import { REQUEST } from '../common/actions';
import plotActions, {
  FETCH_CITY_BANK_ACCOUNTS_DATAS,
  FETCH_CITY_PARCELLE_OWNERS,
  FETCH_PLOT,
  FETCH_PLOTS_BY_USER_ID,
  FETCH_PLOT_BY_PLOT_ID,
  cityParcelleOwnerActions,
} from './actions';

export function* handleFetchPointConnected(): any {
  while (true) {
    try {
      const { payload }: { payload: IFetchPlotRequest } = yield take(
        FETCH_PLOT[REQUEST]
      );
      const { geolocDatas } = yield select(getMapState);
      const { companyId } = yield select(getCompanyState);

      const { rightPanelDisplay, rightPanelContent } = yield select(getPanelState);

      const pointConnectedResponse: {
        parcelle: IPlot;
        map: IMapState;
        layers: IPointConnectedLayers;
      } = yield fetchPointConnectedData({
        ...payload,
        companyId: companyId,
      });

      if (pointConnectedResponse) {
        const plotLocation = payload.plotLocation;

        if (plotLocation) {
          if (
            plotLocation.city &&
            (geolocDatas?.inseeCode === null ||
              geolocDatas?.inseeCode !== plotLocation.inseeCode)
          ) {
            yield put(
              autocompleteActions.setInputValue(
                plotLocation?.address || plotLocation.city
              )
            );
          }

          if (!payload.data.townHall) {
            const temp = { ...payload.plotLocation };
            delete temp.mapCenter;
            yield put(mapActions.geolocSet(temp as GeolocPointInfo));
          } else {
            const response = yield addrServProcessAsync(
              pointConnectedResponse.map.markerLatLng[0],
              pointConnectedResponse.map.markerLatLng[1]
            );

            const temp = { ...response };

            delete temp.mapCenter;
            yield put(mapActions.geolocSet(temp as GeolocPointInfo));
            yield put(autocompleteActions.setInputValue(temp.address));
          }
        }

        if (payload.studyParams?.noCheckExisting) {
          // yield put(mapActions.zoom.set(17.3));
          if (!rightPanelDisplay && !rightPanelContent.prospectionContent) {
            yield put(panelsActions.studyContent.show('prospection'));

            yield put(studyParamsActions.setManagmentLoader(false));
          }
        }
      }
    } catch (error) {
      yield put(studyParamsActions.setManagmentLoader(false));
      yield put(plotActions.fetchPlot.failure([]));
    }
  }
}

// *******************************************************
// ***********COMMON FETCH PLOT REQUEST ****************
// *******************************************************
export function* fetchPointConnectedData({
  data,
  studyParams,
  companyId,
}: IFetchPlotRequest): any {
  try {
    if (!data.lat || !data.lon || !data.department) {
      // no plot found return false and open modal
      yield put(
        modalsActions.alert({
          status: true,
          context: 'noPlotFounded',
          modalType: GenericModalEnum.INFO,
        })
      );
      return false;
    }

    const body = {
      lat: data.lat,
      lon: data.lon,
      town_hall: data.townHall ?? false,
      department: data.department,
      pdf: data.pdf ?? false,
      company_id: companyId ?? '',
    };

    const response = yield call(
      fetchDatas,
      '/get_parcel_datas_from_coordinates',
      body
    );

    if (response?.result.parcel_founded) {
      const parsedResponse = parserService.parcelle.pointConnectedApiToStore(
        response
      );

      yield put(
        pluActions.pluLayerSet({
          pluUrl: parsedResponse?.layers?.pluUrl ?? null,
          insee: parsedResponse.map.inseeCode,
        })
      );

      yield put(
        plotActions.fetchPlot.success({
          ...parsedResponse.parcelle,
          lat: data.lat,
          lon: data.lon,
        })
      );

      yield put(mapActions.plotDatasSet({ ...parsedResponse?.map }));

      if (studyParams) {
        yield put(
          studyParamsActions.setParams({ ...studyParams, managmentLoader: false })
        );
      }

      yield put(authActions.setDeniedZone(false));
      return parsedResponse;
    } else {
      // no plot found return false and open modal
      yield put(
        modalsActions.alert({
          status: true,
          context: 'noPlotFounded',
          modalType: GenericModalEnum.INFO,
        })
      );
      return false;
    }
  } catch (error) {
    yield put(pluActions.InseeLoaderReset());
    switch ((error as any).status) {
      // forbidden, extend subscription modal
      case 403: {
        yield put(
          modalsActions.alert({
            status: true,
            context: 'notIncludedInSubscription',
            modalType: GenericModalEnum.INFO,
          })
        );
        yield put(authActions.setDeniedZone(true));
        break;
      }
      default:
        break;
    }
    yield put(plotActions.fetchPlot.failure(''));
    return false;
  }
}

// *******************************************************
// *********** FETCH PLOT BY PLOT ID ****************
// *******************************************************
export function* handleFetchPlotByPlotId(): any {
  while (true) {
    try {
      const { payload }: { payload: IParcelleByIdPayload } = yield take(
        FETCH_PLOT_BY_PLOT_ID[REQUEST]
      );

      const { companyId } = yield select(getCompanyState);
      const response: any = yield call(
        fetchDataById,
        'get_parcel_datas_from_parcel_id',
        {
          city_zipcode: payload.postalCode,
          parcel_id: payload.plotId,
          pdf: payload.pdf,
          company_id: companyId,
        }
      );

      if (!isEmpty(response.result)) {
        const parsedResponse = parserService.parcelle.fetchByPlotIdApiToStore(
          response
        );
        const loc = parsedResponse.map.markerLatLng as LatLngTuple;
        const location: GeolocPointInfo = yield call(
          addrServProcessAsync,
          loc[0],
          loc[1]
        );

        parsedResponse.map.address = location.address;

        yield put(mapActions.geolocSet(location as GeolocPointInfo));
        yield put(
          autocompleteActions.setInputValue(location.address || location.city)
        );

        if (parsedResponse?.layers?.pluUrl) {
          yield put(
            pluActions.pluLayerSet({
              pluUrl: parsedResponse?.layers?.pluUrl ?? null,
              insee: parsedResponse.map.inseeCode,
            })
          );
        }

        yield put(plotActions.fetchPlotByPlotId.success(parsedResponse.parcelle));
        yield put(mapActions.plotDatasSet(parsedResponse.map));
      } else {
        yield put(
          modalsActions.alert({
            status: true,
            context: 'noPlotFounded',
            modalType: GenericModalEnum.INFO,
          })
        );
      }
    } catch (error) {
      yield put(pluActions.InseeLoaderReset());
      yield put(plotActions.fetchPlot.failure(error));
    }
  }
}

// **************************************************************
// ************  FETCH BANK ACCOUT DATAS **************
// **************************************************************
export function* handleFetchCityBankAccountsDatas(): any {
  while (true) {
    try {
      yield take(FETCH_CITY_BANK_ACCOUNTS_DATAS[REQUEST]);
      const { geolocDatas } = yield select(getMapState);

      const response = yield call(
        fetchDatas,
        '/get_summary_accounting_balance_from_city',
        { insee: geolocDatas?.inseeCode }
      );

      yield handleExportCityBankAccounts(geolocDatas?.city, response);
    } catch (error) {
      yield put(plotActions.fetchCityBankAccountDatas.failure());
    }
  }
}

export function* handleFetchCityParcelleOwners(): any {
  while (true) {
    try {
      const {
        payload: { letters, callback },
      } = yield take(FETCH_CITY_PARCELLE_OWNERS[REQUEST]);

      const { geolocDatas } = yield select(getMapState);

      const response = yield call(fetchDatas, '/search_owner', {
        city_name: geolocDatas?.city,
        city_zipcode: geolocDatas?.postalCode,
        str_search: letters,
      });

      if (response) {
        yield call(callback, response);
      }
    } catch (error) {
      yield put(cityParcelleOwnerActions.fetchOwners.failure(error));
    }
  }
}

// **********************************************************
// **************** FETCH PLOTS BY USER ID*******************
// **********************************************************
export function* handleFetchUsersPlots(): any {
  while (true) {
    try {
      const { payload } = yield take(FETCH_PLOTS_BY_USER_ID[REQUEST]);

      const { users } = yield select(getUsersState);
      const { plotStudyStatuses } = yield select(getAppState);
      const { folders } = yield select(getFoldersState);
      const response = yield genericPagingTraitment(
        fetchDatas,
        `${payload.data}/plot_studies`,
        {
          'createdAt[after]': payload.createdAtAfter,
          'createdAt[before]': payload.createdAtBefore,
        }
      );

      const parsedResponse = plotStudiesParser(
        response,
        users ?? [],
        plotStudyStatuses.result ?? [],
        folders.result
      );

      yield call(payload.callback, parsedResponse);
    } catch (error) {
      yield put(plotActions.fetchPlotsByUserId.failure(error));
    }
  }
}
