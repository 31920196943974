import getEntityFromIdIri from '../../../../lib/parsersTools/getEntityFromIdIri';
import getIdFromIdIri from '../../../../lib/parsersTools/getIdFromIdIri';

export const studyProceduresApiToStoreParser = (
  datas: any[],
  users: Users,
  contactRoles: ContactRoles,
  procedureTypes: ProcedureTypes
): IStudyProcedure[] => {
  try {
    const parsedDatas = datas.map((m: any) =>
      studyProcedureApiToStoreParser(m, users, contactRoles, procedureTypes)
    );

    return parsedDatas;
  } catch (error) {
    throw new Error('global parse error');
  }
};

export const studyProcedureApiToStoreParser = (
  data: any,
  users: Users,
  contactRoles: ContactRoles,
  procedureTypes: ProcedureTypes
): IStudyProcedure => {
  try {
    const parsedData: IStudyProcedure = {
      idIri: data['@id'],
      id: getIdFromIdIri(data['@id']),
      sortValues: { eventDate: data.eventDate },
      comment: data.comment,
      date: data.eventDate,
      responsable: getEntityFromIdIri<User>(data.responsable, users) as User,
      type: getEntityFromIdIri<ProcedureType>(
        data.type,
        procedureTypes
      ) as ProcedureType,
      role: getEntityFromIdIri<ContactRole>(data.role, contactRoles) as ContactRole,
      contact: data.contact ?? null,
    };

    return parsedData;
  } catch (error) {
    throw new Error('single parse error');
  }
};
