import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import {
  fetchReportingIndividualUserThunk,
  fetchUsersStatsThunk,
} from './dashboardReportingThunks';

export enum DashboardReportingSearchTypeEnum {
  OVERALL = 'overall',
  INDIVIDUAL = 'individual',
}

const initialState: DashboardReportingState = {
  searchType: DashboardReportingSearchTypeEnum.OVERALL,
  inputValueReportingOverall: {
    createdAtAfter: null,
    createdAtBefore: null,
  },
  inputValueReportingIndividual: '',
  reportingOverallUsers: { apiStatus: APIStatus.IDLE, result: [] },
  usersStats: { apiStatus: APIStatus.IDLE, result: null },
  reportingIndividualUser: {
    apiStatus: APIStatus.IDLE,
    result: { plotsStatus: [], performanceActivity: null, plots: [] },
  },
};

const dashboardReportingSlice = createSlice({
  name: 'dashboardReporting',
  initialState,
  reducers: {
    setSearchType: (
      state,
      action: PayloadAction<DashboardReportingSearchTypeEnum>
    ) => {
      state.searchType = action.payload;
    },
    setInputValueReportingOverall: (
      state,
      action: PayloadAction<DashboardReportingState['inputValueReportingOverall']>
    ) => {
      state.inputValueReportingOverall = action.payload;
    },
    setInputValueReportingIndividual: (state, action: PayloadAction<string>) => {
      state.inputValueReportingIndividual = action.payload;
    },
    reset: (state) => {
      state.searchType = DashboardReportingSearchTypeEnum.OVERALL;
      state.inputValueReportingOverall = {
        createdAtAfter: new Date(),
        createdAtBefore: new Date(),
      };
      state.inputValueReportingIndividual = '';
      state.reportingOverallUsers = { apiStatus: APIStatus.IDLE, result: [] };
      state.reportingIndividualUser = {
        apiStatus: APIStatus.IDLE,
        result: { plotsStatus: [], performanceActivity: null, plots: [] },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersStatsThunk.pending, (state) => {
        state.usersStats.apiStatus = APIStatus.PENDING;
        state.usersStats.result = null;
        state.usersStats.error = undefined;
      })
      .addCase(
        fetchUsersStatsThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.usersStats.apiStatus = APIStatus.FULFILLED;
          state.usersStats.result = action.payload ?? [];
          state.usersStats.error = action.payload.error;
        }
      )
      .addCase(
        fetchUsersStatsThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.usersStats.apiStatus = APIStatus.REJECTED;
          state.usersStats.result = null;
          state.usersStats.error = action.payload;
        }
      );
    // GET ONE
    builder
      .addCase(fetchReportingIndividualUserThunk.pending, (state) => {
        state.reportingIndividualUser.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchReportingIndividualUserThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.reportingIndividualUser.apiStatus = APIStatus.FULFILLED;
          state.reportingIndividualUser.result = action.payload ?? {
            plotsStatus: [],
            performanceActivity: null,
            plots: [],
          };
        }
      )
      .addCase(
        fetchReportingIndividualUserThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.usersStats.apiStatus = APIStatus.REJECTED;
          state.usersStats.result = null;
          state.usersStats.error = action.payload;
        }
      );
  },
});

export default dashboardReportingSlice.reducer;
export const dashboardReportingActions = dashboardReportingSlice.actions;
export const getDashboardReportingState = (state: RootState) =>
  state.dashboardReporting;
