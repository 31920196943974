import { call, put, select, take } from 'redux-saga/effects';
import { getCompanyState } from '../../features/company/companySlice';
import { parserService } from '../../services/ParserService';
import { fetchDatas } from '../../services/axiosFiles/genericCrud';
import { REQUEST } from '../common/actions';
import contactActions, { FETCH_CONTACTS } from './action';

// *********************************************************
// ******************* FETCH CONTACTS *********************
// *********************************************************
export function* handleFetchContacts(): any {
  while (true) {
    try {
      const { payload } = yield take(FETCH_CONTACTS[REQUEST]);
      const { companyIdIri } = yield select(getCompanyState);

      if (companyIdIri) {
        const response = yield call(
          fetchDatas,
          `${companyIdIri}/contacts${payload}`,
          payload.criterias
        );

        const parsedResponse = parserService.contacts.contactsApiToStore(
          response['hydra:member']
        );

        yield put(
          contactActions.fetchAll.success(
            parsedResponse,
            response['hydra:totalItems']
          )
        );
      } else {
        console.log('Company non chargée');
      }
    } catch (error) {
      yield put(contactActions.fetchAll.failure(error));
    }
  }
}
