export const envParse = (data: nullable<string[]>): IEnvPDFDisplay => {
  return data ? { indice: data[0], libelle: data[1] } : { libelle: '-' };
};

export const envPdfParser = (env: IParcelleEnv[], cat: number) => {
  return env
    .find((e) => e.cat === cat)
    ?.state[0].split('-')
    .map((m) => m.trim());
};
