import { postEntityNoToken } from '../../services/axiosFiles/genericCrud';
import axiosInstance from '../../services/AxiosPublicInstance';
import LocalStorageService from '../../services/LocalStorageService';

export function loginRequest(datas: ILoginParams) {
  return new Promise<number>((resolve, reject) => {
    axiosInstance()
      .post('/authentication_token', datas)
      .then(
        (response) => {
          const r = response.data;

          // set localStorage with token and refresh token
          LocalStorageService.setToken(r.token);
          LocalStorageService.setRefreshToken(r.refresh_token);

          //return status 200
          resolve(200);
        },
        (error) => {
          reject(error);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}

export const fetchRefreshToken = async (refresh_token: string | null) => {
  return new Promise<ILoginResponse>((resolve, reject) => {
    if (refresh_token) {
      const promise = postEntityNoToken({
        endpoint: '/refresh_token',
        body: { refresh_token },
      });
      promise.then(
        (response: any) => {
          return resolve({
            token: response.token,
            refreshToken: response.refresh_token,
          });
        },
        (err) => {
          reject(err);
        }
      );
    }
  });
};
