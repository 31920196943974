export const validationMessages = {
  email: { requis: 'Le mail est requis', error: "Le mail n'est pas conforme" },
  phone: {
    requis: 'Le téléphone est requis',
    error: "Le numéro n'est pas conforme",
  },
  contactRole: { requis: 'Le rôle du contact est requis' },
  contactFirstName: { requis: 'Le prénom est requis' },
  contactLastName: { requis: 'Le nom est requis' },
  interlocutor: { requis: "L'interlocuteur est requis" },
  contact: { requis: 'Le contact est requis' },
  procedureType: { requis: "L'action est requise" },
  lastName: { requis: 'Le nom est requis' },
  firstName: { requis: 'Le prénom est requis' },
};
