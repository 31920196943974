import { isEmpty } from 'lodash';

export const zoneAndZoneChildDisplay = (zone: any): string => {
  if (!zone) {
    return 'Indéfini';
  }
  const keys = Object.keys(zone);
  const zoneChild = keys.includes('zone_child') ? 'zone_child' : 'zoneChild';

  return isEmpty(zone[zoneChild]) ? zone.zone : `${zone.zone}-${zone[zoneChild]}`;
};

export const documentsBuilder = (data: any): IPlotDocument[] => {
  const documents: IPlotDocument[] = [];

  Object.keys(data).forEach((d) => {
    const temp: string[] = data[d];

    documents.push({
      type: d,
      files: temp,
    });
  });

  return documents;
};
