import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { servitudesDatas } from './constant';
import { checkRecursive, displayedLayers } from './methods';

const initialState: ServitudesState = {
  servitudesDisplayed: [],
  servitudesChecked: servitudesDatas,
};

const servitudesSlice = createSlice({
  name: 'servitudes',
  initialState,
  reducers: {
    setServitudeOpen: (
      state,
      action: PayloadAction<{ servitude: Servitude; value: boolean }>
    ) => {
      const servitude = action.payload.servitude;
      const isOpen = action.payload.value;
      state.servitudesChecked = checkRecursive(
        state.servitudesChecked,
        servitude,
        isOpen,
        'isOpen'
      );
    },

    setServitudesChecked: (
      state,
      action: PayloadAction<{ servitude: Servitude; checked: boolean }>
    ) => {
      const servitudeAction = action.payload.servitude;
      const checked = action.payload.checked;

      // update checked rows
      const checkedServitudes = checkRecursive(
        state.servitudesChecked,
        servitudeAction,
        checked,
        'check'
      );

      //update layers tab with check result
      const displayedTab: string[] = [];
      displayedLayers(checkedServitudes, displayedTab);

      state.servitudesChecked = checkedServitudes;
      state.servitudesDisplayed = displayedTab;
    },
  },
});

export default servitudesSlice.reducer;
export const servitudeActions = servitudesSlice.actions;
export const getNationalServitudesState = (state: RootState) =>
  state.nationalServitudes;
