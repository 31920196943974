import { dateFormat } from '../../lib/formats/dataFormat';
import getEntityFromIdIri from '../../lib/parsersTools/getEntityFromIdIri';

export const multiProcedureParser = (
  datas: any[],
  procedureTypes: ProcedureTypes,
  studyStatuses: StudyStatuses,
  contactRoles: ContactRoles
): Procedure[] => {
  const parsedProcedures = datas.map((m) => {
    return singleProcedureParser(m, procedureTypes, studyStatuses, contactRoles);
  });

  return parsedProcedures;
};

export const singleProcedureParser = (
  data: any,
  procedureTypes: ProcedureTypes,
  studyStatuses: StudyStatuses,
  contactRoles: ContactRoles
): Procedure => {
  const entityBuilder = (data: any): ProcedureEntity => {
    if (data.plotStudy) {
      const ps = data.plotStudy;
      return {
        idIri: ps['@id'],
        name: ps.uniquePlotId,
        status: getEntityFromIdIri(ps.status, studyStatuses) ?? null,
        city: ps.cityName,
        department: ps.zipCode.substring(0, 2),
        lat: ps.latitude,
        lng: ps.longitude,
      };
    } else if (data.folder) {
      const f = data.folder;
      return {
        idIri: f['@id'],
        name: f.name,
        status: getEntityFromIdIri(f.status, studyStatuses) ?? null,
      };
    } else {
      const s = data.sector;
      return {
        idIri: s['@id'],
        name: s.name,
        status: null,
      };
    }
  };

  const entityData = entityBuilder(data);
  const type = getEntityFromIdIri(data.type, procedureTypes) as ProcedureType;

  const parsedData: Procedure = {
    sortValues: {
      eventDate: data.eventDate,
      city: entityData.city ?? '-',
      type: type.label,
      status: entityData.status?.label ?? '-',
    },
    idIri: data['@id'],
    type,
    contactRole: getEntityFromIdIri(data.contactRole, contactRoles),
    eventDate: dateFormat(data.eventDate),
    entity: entityBuilder(data),

    contact: data.contact
      ? ({
          idIri: data.contact['@id'],
          firstName: data.contact.firstName,
          lastName: data.contact.lastName,
        } as IContact)
      : null,
  };

  return parsedData;
};
