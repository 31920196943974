import { createAsyncThunk } from '@reduxjs/toolkit';
import { InternalError } from '../../services/axiosFiles/axiosUtils';
import { fetchDatas, postEntity } from '../../services/axiosFiles/genericCrud';
import { loadersActions } from '../loaders/loaderSlice';
import { modalsActions } from '../modals/modalsSlice';
import { userApiToStoreParser, usersApiToStoreParser } from './usersParser';
import { userUpdate } from './usersRequest';

export const fetchUsersThunk = createAsyncThunk(
  'users/fetchUsersThunk',
  async (params: { companyIdIri: string | null }, { rejectWithValue }) => {
    if (params.companyIdIri) {
      try {
        const result = await fetchDatas(`${params.companyIdIri}/users`);

        const parsedUsers = usersApiToStoreParser(result['hydra:member']);

        const response: {
          user_id: number;
          procedures_count: number;
          plot_studies_count: any;
          user_logs_count: any;
        }[] = await fetchDatas(`${params.companyIdIri}/users_stats`);

        const users = parsedUsers.map((elt) => {
          const stat = response.find((e) => e.user_id === elt.id);
          return {
            ...elt,
            procedureCount: stat?.procedures_count ?? 0,
            plotStudyCount:
              stat?.plot_studies_count !== 0 ? stat?.plot_studies_count.total : 0,
          };
        });

        return users;
      } catch (error) {
        return rejectWithValue(InternalError(error));
      }
    } else {
      return rejectWithValue({
        status: 422,
        message: 'comanyIdIri param missing',
      });
    }
  }
);
export const userCreateThunk = createAsyncThunk(
  'users/createUserThunk',
  async (
    params: {
      user: Pick<
        User,
        'firstName' | 'lastName' | 'email' | 'plainPassword' | 'roles'
      > | null;
      companyIdIri: string | null;
    },
    { dispatch, rejectWithValue }
  ) => {
    const { user, companyIdIri } = params;

    if (user && companyIdIri) {
      try {
        dispatch(loadersActions.loaderShow());

        // create user without company
        const result = await postEntity({
          endpoint: `/users`,
          body: { ...user, isActive: true },
        });
        const parsedUser = await userApiToStoreParser(result);

        // add user to selected company
        await postEntity({
          endpoint: `/company_users`,
          body: {
            company: companyIdIri,
            user: parsedUser.idIri,
            roles: parsedUser.roles,
          },
        });

        dispatch(loadersActions.loaderHide());
        dispatch(
          modalsActions.addUserResult({
            isEdit: false,
            isOpen: true,
          })
        );
        return parsedUser;
      } catch (error) {
        dispatch(loadersActions.loaderHide());
        dispatch(
          modalsActions.addUserResult({
            isEdit: false,
            isOpen: true,
          })
        );
        return rejectWithValue(InternalError(error));
      }
    } else {
      dispatch(loadersActions.loaderHide());
      return rejectWithValue({
        status: 422,
        message: 'comanyIdIri param missing or user value missing',
      });
    }
  }
);
export const userUpdateThunk = createAsyncThunk(
  'users/updateUserThunk',
  async (
    params: {
      userIdIri: string;
      user:
        | Pick<User, 'firstName' | 'lastName' | 'email'>
        | Pick<User, 'isActive'>
        | Pick<User, 'plainPassword'>
        | Pick<User, 'roles'>;
    },
    { dispatch, rejectWithValue }
  ) => {
    const { userIdIri, user } = params;

    if (user && userIdIri) {
      try {
        dispatch(loadersActions.loaderShow());
        const result = await userUpdate(userIdIri, user);

        dispatch(loadersActions.loaderHide());
        dispatch(
          modalsActions.addUserResult({
            isEdit: true,
            isOpen: true,
          })
        );
        return result;
      } catch (error) {
        dispatch(loadersActions.loaderHide());
        dispatch(
          modalsActions.addUserResult({
            isEdit: false,
            isOpen: true,
          })
        );
        return rejectWithValue(InternalError(error));
      }
    } else {
      dispatch(loadersActions.loaderHide());
      return rejectWithValue({
        status: 422,
        message: 'user value missing',
      });
    }
  }
);

export const userPasswordUpdateThunk = createAsyncThunk(
  'users/userPasswordUpdateThunk',
  async (
    params: {
      userIdIri: string;
      user: Partial<User>;
    },
    thunkAPI
  ) => {
    const { userIdIri, user } = params;

    if (user && userIdIri) {
      try {
        const result = userUpdate(userIdIri, user);

        return result.then(
          (response) => {
            return response;
          },
          (err) => {
            return thunkAPI.rejectWithValue(err);
          }
        );
      } catch (error) {
        return thunkAPI.rejectWithValue(InternalError(error));
      }
    } else {
      return thunkAPI.rejectWithValue({
        status: 422,
        message: 'user value missing',
      });
    }
  }
);
