import { InternalError } from '../../../services/axiosFiles/axiosUtils';
import { fetchFolderPlotStudies } from '../../folders/services/fetchFolderPlotStudies';
import { getEntityAndSubsUniqueTab } from '../utils';
import { plotStudiesToEntitiesDisplay } from '../utils/entitiesDisplayParsers';

const fetchFolderAndSubsPlotStudiesForDisplay = async (
  folder: IFolder,
  users: Users,
  plotStudyStatuses: StudyStatuses
) => {
  try {
    const folders = getEntityAndSubsUniqueTab(folder);
    const request = folders.map((f) =>
      fetchFolderPlotStudies(f as IFolder, {}, users ?? [], plotStudyStatuses ?? [])
    );
    const promises = await Promise.all(request);

    const parsed = promises.map((m) => plotStudiesToEntitiesDisplay(m));

    return { entities: parsed.flat(1), folderIdIris: folders.map((m) => m.idIri) };
  } catch (error) {
    return Promise.reject(InternalError(error));
  }
};

export default fetchFolderAndSubsPlotStudiesForDisplay;
