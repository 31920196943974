import { axiosTokenInstance } from '../../services/AxiosProtectedInstance';
import { paramsNotFound } from '../../services/axiosFiles/axiosUtils';

const PROXY_PYTHON_URL = process.env.REACT_APP_API_URL_DATAS;

type FetchInseeCodeResult = {
  department: string;
  inseeCode: string;
  lat: number;
  lng: number;
};
export async function fetchInseeFromLatLngDep(
  lat: number,
  lon: number,
  department: string
): Promise<FetchInseeCodeResult> {
  try {
    if (lat && lon && department) {
      const result = await axiosTokenInstance().get(
        `${PROXY_PYTHON_URL}/get_municipality_code_from_coordinates/`,
        { params: { lon, lat, department } }
      );

      const parsedResult = {
        department: result.data.department,
        lat: result.data.lat,
        lng: result.data.lon,
        inseeCode: result.data.insee,
      };

      return parsedResult;
    } else {
      return Promise.reject(paramsNotFound());
    }
  } catch (error: any) {
    return Promise.reject(error.message);
  }
}
