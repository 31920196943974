import { useAppDispatch } from '../../App/store';
import { modalsActions } from '../../features/modals/modalsSlice';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from './GenericModal';

const getContext = (context: string | null) => {
  switch (context) {
    case 'deleteFolder':
      return {
        title: 'Suppression de dossier',
        content:
          'Impossible de supprimer un dossier qui contient des parcelles en etude, ou des sous dossiers non vide.',
      };
    case 'deleteSector':
      return {
        title: 'Suppression de secteur',
        content:
          'Impossible de supprimer un secteur qui contient des sous secteurs.',
      };
    case 'noPlotFounded':
      return {
        title: 'Parcelle',
        content: 'Aucune parcelle a été trouvée.',
      };
    case 'noUserAuth':
      return {
        title: 'Authentification utilisateur',
        content:
          'Une erreur est survenue lors du chargement des données utilisateur, veuillez contacter urbanease.',
      };
    case 'notIncludedInSubscription':
      return {
        title: 'Parcelle',
        content:
          'Cette parcelle se situe dans une zone non couverte par votre abonnement. Veuillez contacter urbanease pour étendre votre souscription.',
      };
    case 'resetPasswordFail':
      return {
        title: 'Changement de mot de passe',
        content:
          "Les données d'identification que vous avez fournies pour réinitialiser votre mot de passe ne sont pas valides. Il est possible que votre session ait expiré. Si vous rencontrez toujours des problèmes, n'hésitez pas à contacter le support d'Urbanease.",
      };

    default:
      return { title: '', content: '' };
  }
};
interface IAlertProps {
  modalType: GenericModalEnum;
  context: AlertContextType;
}

function Alert({ modalType, context }: IAlertProps) {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(modalsActions.alertClose());
  };

  const display = getContext(context);
  return (
    <GenericModal
      actionType={GenericModalActionEnum.OK}
      modalType={modalType}
      title={display.title}
      closeModal={handleCloseModal}
      okButtonContent="OK"
      okCallback={handleCloseModal}
    >
      <div>{display.content}</div>
    </GenericModal>
  );
}

export default Alert;
