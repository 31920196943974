import { useEffect } from 'react';
import './styles/home.scss';

const HomePage = (): JSX.Element => {
  useEffect(() => {
    window.location.href = 'https://app.urbanease.io/';
  }, []);

  return <div className="home-container"></div>;
};

export default HomePage;
