interface IZoneAndZoneChild {
  zone: string | null;
  zoneChild: string | null;
}
export function getZoneAndZoneChildString(value: IZoneAndZoneChild) {
  const { zone, zoneChild } = value;
  if (!zone) {
    return 'Indéfini';
  }

  return zoneChild ? `${zone}-${zoneChild}` : zone;
}
