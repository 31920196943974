import * as G from 'geojson';
import L, { LatLng, LatLngTuple } from 'leaflet';
import { isArray } from 'lodash';
import { getEntityAndSubsUniqueTab, sectorColorDisplay } from '.';
import { SECTOR_OWNER_COLOR } from '../../../shared/constants';

export const sectorsToEntitiesDisplay = (
  sectors: Sectors | ISector,
  userIdIri: string | null
) => {
  try {
    let entities: EntitiesDisplay = [];
    const sectorTab = isArray(sectors) ? sectors : [sectors];
    sectorTab.forEach((s) => {
      const sTab = getEntityAndSubsUniqueTab(s) as Sectors;
      const filteredSectors = sTab.filter((f) => f.wkb);
      const ent = filteredSectors.map((m) =>
        sectorToEntityDisplay(m as ISector, userIdIri)
      );
      entities = entities.concat(ent);
    });

    return entities;
  } catch (error) {
    throw new Error('sectorsToEntitiesDisplay error');
  }
};

export const sectorToEntityDisplay = (sector: ISector, userIdIri: string | null) => {
  try {
    const polygonBounds: LatLngTuple[] = (sector.wkb.features[0]
      .geometry as G.Polygon).coordinates[0].map((t: number[]) => [t[1], t[0]]);
    const center = L.polygon(polygonBounds).getBounds().getCenter();
    const isSub = Boolean(sector.parent);
    const parsed: EntityDisplay = {
      type: 'sector',
      idIri: sector.idIri,
      isSub,
      color: sectorColorDisplay(sector, userIdIri),
      index: sector.indexId as number,
      coordinates: center,
      polygon: polygonBounds,
    };

    return parsed;
  } catch (error) {
    throw new Error('sectorToEntityDisplay error');
  }
};

export const SectorOwnerUpdateParser = (ss: EntitiesDisplay, sectorIdIi: string) =>
  ss.map((m) => (m.idIri === sectorIdIi ? { ...m, color: SECTOR_OWNER_COLOR } : m));
export const plotStudiesToEntitiesDisplay = (
  plotStudies: PlotStudies,
  color?: string
) => {
  try {
    const entities: EntitiesDisplay = plotStudies.map((ps, index) => {
      return plotStudyToEntityDisplay(ps, index + 1, color);
    });

    return entities;
  } catch (error) {
    return [];
  }
};

export const plotStudyToEntityDisplay = (
  ps: IPlotStudy,
  index: number,
  color?: string
) => {
  const parsed: EntityDisplay = {
    type: 'folder',
    idIri: ps.idIri,
    color: color ?? ps.markerColor ?? '',
    parent: ps.parent.idIri,
    index: index,
    coordinates: { lat: ps.lat, lng: ps.lng } as LatLng,
  };

  return parsed;
};
