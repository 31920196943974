import getEntityFromIdIri from '../../lib/parsersTools/getEntityFromIdIri';
import getIdFromIdIri from '../../lib/parsersTools/getIdFromIdIri';
import genericSort from '../../utils/genericSort';

export const sectorsParser = (datas: any[], users: Users): ISector[] => {
  try {
    const tempSectors = datas.map((m) => sectorParser(m, users));

    const sortedTempSectors = genericSort(tempSectors, 'asc', 'id') as ISector[];
    const masterSectors = sortedTempSectors
      .filter((f) => !f.parent)
      .map((f, i) => ({
        ...f,
        indexId: i + 1,
      }));

    masterSectors.forEach((sector) => {
      const subs = tempSectors
        .filter((f) => f.parent === sector.idIri)
        .map((f, i) => ({
          ...f,
          indexId: i + 1,
        }));

      sector.subs = genericSort(subs, 'asc', 'id');
    });

    return masterSectors;
  } catch (error) {
    return [];
  }
};

export const sectorParser = (data: any, users: Users): ISector => {
  try {
    const parsedData: ISector = {
      idIri: data['@id'],
      id: getIdFromIdIri(data['@id']),
      indexId: 0,
      name: data.name,
      parent: data.parentSector
        ? typeof data.parentSector === 'string'
          ? data.parentSector
          : data.parentSector['@id']
        : null,
      responsable: getEntityFromIdIri(data.responsable, users) as User,
      wkb: JSON.parse(data.wkbGeometry),
      subCount: data.subSectors.length,
      subs: null,
    };

    return parsedData;
  } catch (error) {
    throw new Error("the sector can't be parsed");
  }
};
