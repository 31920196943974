import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import {
  fetchMultiPDFPlotThunk,
  fetchSinglePDFPlotThunk,
} from './services/thunks/generatePDFThunks';

const initialState: GeneratePDFState = {
  data: { apiStatus: APIStatus.IDLE, result: null },
  next: false,
  timeouts: [],
};

const generatePDFSlice = createSlice({
  name: 'generatePDF',
  initialState,
  reducers: {
    reset: () => initialState,
    setNext: (state, action: PayloadAction<boolean>) => {
      state.next = action.payload;
    },
    pushTimeout: (state, action: PayloadAction<number>) => {
      state.timeouts = state.timeouts.concat(action.payload);
    },
    shiftTimeout: (state, action: PayloadAction<number>) => {
      state.timeouts = state.timeouts.filter((f) => f !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSinglePDFPlotThunk.pending, (state) => {
        state.data.apiStatus = APIStatus.PENDING;
        state.data.result = null;
        state.data.error = undefined;
      })
      .addCase(
        fetchSinglePDFPlotThunk.fulfilled,
        (state, action: PayloadAction<PlotPDFDocument>) => {
          state.data.apiStatus = APIStatus.IDLE;
          state.data.result = action.payload;
          state.data.error = undefined;
        }
      )
      .addCase(
        fetchSinglePDFPlotThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.data.apiStatus = APIStatus.REJECTED;
          state.data.result = null;
          state.data.error = action.payload;
        }
      )
      .addCase(fetchMultiPDFPlotThunk.pending, (state) => {
        state.data.apiStatus = APIStatus.PENDING;
        state.data.result = null;
        state.next = false;
        state.data.error = undefined;
      })
      .addCase(
        fetchMultiPDFPlotThunk.fulfilled,
        (state, action: PayloadAction<PlotPDFDocument>) => {
          state.data.apiStatus = APIStatus.IDLE;
          state.data.result = action.payload;
          state.data.error = undefined;
        }
      )
      .addCase(
        fetchMultiPDFPlotThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.data.apiStatus = APIStatus.REJECTED;
          state.data.result = null;
          state.data.error = action.payload;

          if (action.payload.message === 'timeout') {
            state.next = true;
            state.timeouts = state.timeouts.concat(action.payload.error.count);
          }
        }
      );
  },
});

export default generatePDFSlice.reducer;
export const generatePDFActions = generatePDFSlice.actions;
export const getGeneratePDFState = (state: RootState) => state.generatePDF;
