import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDatas } from '../../services/axiosFiles/genericCrud';
import { buildPermitsParser } from './builtPermitsParser';

export const fetchBuildPermitsThunk = createAsyncThunk(
  'buildPermits/fetchBuildPermises',
  async (params: { postalCode: string; city: string }) => {
    const { postalCode, city } = params;
    const response = await fetchDatas('/get_building_permis_datas_from_city', {
      city_zipcode: postalCode,
      city_name: city,
    });
    const parsedResponse = buildPermitsParser(response.result);

    return {
      buildPermits: parsedResponse,
      postalCode,
      city,
      department: postalCode.substring(0, 2),
    };
  }
);
