import {
  changeApiKeyForStoreKey,
  mapObjectParser,
} from '../../services/common/parserCommon';

export const userMapping = {
  '@id': 'idIri',
  companyUsers: 'companyUsers',
  email: 'email',
  firstName: 'firstName',
  isActive: 'isActive',
  lastName: 'lastName',
  roles: 'roles',
  favoriteSearches: 'favoriteSearches',
};

export const usersApiToStoreParser = (data: any) => {
  const parsedUsers: Users = mapObjectParser(data, userApiToStoreParser);

  // no company list on userTab, only on single
  return parsedUsers.map((m) => ({ ...m, companies: null }));
};

export const userApiToStoreParser = (data: any): User => {
  try {
    const parsedData = changeApiKeyForStoreKey<User>(data, userMapping);

    parsedData.id = parseInt(parsedData.idIri.split('/')[2]);
    parsedData.fullName = parsedData.firstName + ' ' + parsedData.lastName;
    parsedData.plotStudyCount = data?.plotStudies?.length ?? 0;
    parsedData.procedureCount = data?.procedures?.length ?? 0;
    parsedData.isManager = data?.roles.some((f: string) => f === 'ROLE_MANAGER');
    parsedData.isAdmin = data?.roles.some((f: string) => f === 'ROLE_ADMIN');

    return parsedData;
  } catch (error: any) {
    throw new Error('Erreur parsig user - ' + error.message);
  }
};
