import { createAsyncThunk } from '@reduxjs/toolkit';
import { postEntity } from '../../services/axiosFiles/genericCrud';
import { brownfieldsApiToStoreParser } from './brownfieldsParser';

export const fetchBrownfieldsThunk = createAsyncThunk(
  'dpe/fetchBrownfieldsThunk',
  async (params: { insee: string | null }, { rejectWithValue }) => {
    try {
      if (params.insee) {
        const response: any = await postEntity({
          endpoint: '/data_urbanease/get_brownfields_from_city/',
          body: { insee: params.insee },
        });

        const parsedResponse = brownfieldsApiToStoreParser(response);

        return parsedResponse;
      } else {
        return rejectWithValue({ status: 0, message: 'no params' });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
